import { Alert, Autocomplete, Breadcrumbs, Button, Checkbox, Divider, Fade, FormControl, FormControlLabel, InputAdornment, InputLabel, Link, MenuItem, OutlinedInput, Rating, Select, Snackbar, TextField, Typography } from "@mui/material";
import MenuAppBar from "./MenuAppBar";
import { ArrowDownward, Clear, DeleteOutlined, MoreVert, NavigateNext, Save } from "@mui/icons-material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getFirestore, addDoc, collection, updateDoc, query, where, orderBy, limit, getDocs, setDoc, doc, getDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { ERRO_CARREGAR_ORCAMENTO, ERRO_SALVAR_ORCAMENTO, sendError } from "../Errors";
import { DatePicker, DateTimePicker, TimeField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Footer from "./Footer";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { AEROPORTOS } from "../aeroportos";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

function NewQuote(props) {

    const navigate = useNavigate();
    let { quoteId } = useParams();

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState("success");
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [salvarDisabled, setSalvarDisabled] = useState(false);

    // Cliente search
    const [clienteOptions, setClienteOptions] = useState([]);
    const [clienteValue, setClienteValue] = useState("");

    const [nomeCliente, setNomeCliente] = useState("");
    const [numeroAdultos, setNumeroAdultos] = useState("");
    const [numeroCriancas, setNumeroCriancas] = useState("");
    const [idadeCriancas, setIdadeCriancas] = useState([]);

    const tipoTransporteObj = {
        "empresa": "",
        "dataInicio": "",
        "dataFinal": "",
        "origem": "",
        "destino": "",
        "partida": "",
        "chegada": "",
        "bagagem_mao": false,
        "bagagem_despachada": false
    };
    const [tiposTransporte, setTiposTransporte] = useState([tipoTransporteObj]);

    const hospedagemObj = {
        "destino": "",
        "nome_hospedagem": "",
        "classificacao": 0,
        "dataInicio": "",
        "dataFinal": "",
        "tipo_acomodacao": "",
        "regime_alimentacao": ""
    };
    const [hospedagens, setHospedagens] = useState([hospedagemObj]);
    const [servicos, setServicos] = useState([""]);
    const [observacoes, setObservacoes] = useState("");
    const [valorOrcamento, setValorOrcamento] = useState("0.00");
    const [descontoOrcamento, setDescontoOrcamento] = useState("0.00");
    const [acrescimoOrcamento, setAcrescimoOrcamento] = useState("0.00");
    const [valorFinalOrcamento, setValorFinal] = useState("0.00");
    const [formaPagamento, setFormaPagamento] = useState("");
    const [taxasIncluidas, setTaxasIncluidas] = useState(false);
    const [taxasValor, setTaxasValor] = useState("0.00");

    const [situacaoOrcamento, setSituacaoOrcamento] = useState("");
    const [dataExpiracao, setDataExpiracao] = useState(null);
    const [imagem, setImagem] = useState(null);

    const modeRef = useRef("");

    const db = getFirestore(getApp());
    const storage = getStorage();
    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {

        if (quoteId) {

            modeRef.current = "edit";

            async function fetchQuote() {

                const quoteRef = doc(db, "quotes", quoteId);
                const docSnap = await getDoc(quoteRef);
                const docData = docSnap.data();
                console.log("quote", docData);

                setNomeCliente(docData.nomeCliente ?? "");
                setNumeroAdultos(docData.numeroAdultos ?? "");
                setNumeroCriancas(docData.numeroCriancas ?? "");
                setIdadeCriancas(docData.idadeCriancas ?? []);
                setTiposTransporte(docData.tiposTransporte ?? [tipoTransporteObj]);
                setHospedagens(docData.hospedagens ?? [hospedagemObj]);
                setServicos(docData.servicos ?? [""]);
                setObservacoes(docData.observacoes ?? "");
                setValorOrcamento(docData.valorOrcamento ?? "0.00");
                setDescontoOrcamento(docData.descontoOrcamento ?? "0.00");
                setAcrescimoOrcamento(docData.acrescimoOrcamento ?? "0.00");
                //setValorFinalOrcamento(docData.valorFinalOrcamento ?? "0.00");
                setFormaPagamento(docData.formaPagamento ?? "");
                setTaxasValor(docData.taxasValor ?? "0.00");
                setTaxasIncluidas(docData.taxasIncluidas ?? false);
                setSituacaoOrcamento(docData.situacaoOrcamento ?? "");
                setDataExpiracao(timestampToDayjs(docData.dataExpiracao));
                setImagem(docData.imagem ?? null);

            }

            try {
                fetchQuote();
            } catch (error) {
                sendError(ERRO_CARREGAR_ORCAMENTO, error);
                navigate("/error/" + ERRO_CARREGAR_ORCAMENTO.code);
            }

        } else {
            modeRef.current = "new";
            setSituacaoOrcamento("Novo");
            setDataExpiracao(dayjs().add(2, 'day'));
        }

    }, []);

    const timestampToDayjs = (timestamp) => {
        if (!timestamp) {
            return null;
        }
        return dayjs(timestamp.toDate());
    }

    const onSave = async () => {

        if (!nomeCliente) {
            setSnackBarSeverity("error");
            setSnackBarMessage("Informe o nome do cliente.")
            setSnackBarOpen(true);
            return;
        }

        if (!numeroAdultos) {
            setSnackBarSeverity("error");
            setSnackBarMessage("Informe o número de adultos.")
            setSnackBarOpen(true);
            return;
        }

        for (let i = 0; i < hospedagens.length; i++) {
            if (hospedagens[i].destino) {
                if (!hospedagens[i].nome_hospedagem
                    || !hospedagens[i].dataInicio
                    || !hospedagens[i].dataFinal
                    || !hospedagens[i].regime_alimentacao) {
                    setSnackBarSeverity("error");
                    setSnackBarMessage("Informe os dados da hospedagem: nome, datas e regime de alimentação.")
                    setSnackBarOpen(true);
                    return;
                }
            }
        }

        for (let i = 0; i < tiposTransporte.length; i++) {
            if (tiposTransporte[i].empresa) {
                if (!tiposTransporte[i].dataInicio
                    || !tiposTransporte[i].dataFinal
                    || !tiposTransporte[i].origem
                    || !tiposTransporte[i].destino
                    || !tiposTransporte[i].partida
                    || !tiposTransporte[i].chegada) {
                    setSnackBarSeverity("error");
                    setSnackBarMessage("Informe os dados do tipo de transporte.")
                    setSnackBarOpen(true);
                    return;
                }
            }
        }

        if (valorOrcamento == "0.00") {
            setSnackBarSeverity("error");
            setSnackBarMessage("Informe o valor do orçamento.")
            setSnackBarOpen(true);
            return;
        }

        if (!formaPagamento) {
            setSnackBarSeverity("error");
            setSnackBarMessage("Informe a forma de pagamento.")
            setSnackBarOpen(true);
            return;
        }

        setSalvarDisabled(true);

        try {

            // Save image
            let imagemNova = null;
            if (imagem instanceof File) {
                // Create a ref with unique name and upload
                let uuid = crypto.randomUUID();
                let extension = imagem.name.split('.').pop();
                imagemNova = "images/" + user.uid + "/" + uuid + "." + extension;
                let imageRef = ref(storage, imagemNova);
                await uploadBytes(imageRef, imagem);
            }

            // Remove unneeded ages
            idadeCriancas.length = numeroCriancas ?? 0;

            let quoteRef;

            if (modeRef.current === "new") {

                let today = new Date();
                quoteRef = await addDoc(collection(db, "quotes"),
                    {
                        "userId": props.user.uid,
                        "numeroOrcamento": generateQuoteNumber(),
                        "dataOrcamento": today,
                        "dataOrcamentoString": today.toLocaleDateString("pt-BR"),
                        "nomeCliente": nomeCliente.trim(),
                        "numeroAdultos": numeroAdultos,
                        "numeroCriancas": numeroCriancas,
                        "idadeCriancas": idadeCriancas,
                        "tiposTransporte": tiposTransporte,
                        "hospedagens": hospedagens,
                        "servicos": servicos,
                        "observacoes": observacoes,
                        "valorOrcamento": valorOrcamento,
                        "descontoOrcamento": descontoOrcamento,
                        "acrescimoOrcamento": acrescimoOrcamento,
                        "valorFinalOrcamento": valorFinalOrcamento,
                        "formaPagamento": formaPagamento,
                        "taxasValor": taxasValor,
                        "taxasIncluidas": taxasIncluidas,
                        "situacaoOrcamento": situacaoOrcamento,
                        "dataExpiracao": dataExpiracao ? dataExpiracao.toDate() : "",
                        "imagem": imagemNova ?? imagem
                    }
                );

                //Add id as field
                await updateDoc(quoteRef, {
                    orcamentoId: quoteRef.id
                });

            } else {

                quoteRef = doc(db, "quotes", quoteId);
                await updateDoc(quoteRef,
                    {
                        //"userId": props.user.uid,
                        //"numeroOrcamento": generateQuoteNumber(),
                        //"dataOrcamento": today,
                        //"dataOrcamentoString": today.toLocaleDateString("pt-BR"),
                        "nomeCliente": nomeCliente.trim(),
                        "numeroAdultos": numeroAdultos,
                        "numeroCriancas": numeroCriancas,
                        "idadeCriancas": idadeCriancas,
                        "tiposTransporte": tiposTransporte,
                        "hospedagens": hospedagens,
                        "servicos": servicos,
                        "observacoes": observacoes,
                        "valorOrcamento": valorOrcamento,
                        "descontoOrcamento": descontoOrcamento,
                        "acrescimoOrcamento": acrescimoOrcamento,
                        "valorFinalOrcamento": valorFinalOrcamento,
                        "formaPagamento": formaPagamento,
                        "taxasValor": taxasValor,
                        "taxasIncluidas": taxasIncluidas,
                        "situacaoOrcamento": situacaoOrcamento,
                        "dataExpiracao": dataExpiracao ? dataExpiracao.toDate() : "",
                        "imagem": imagemNova ?? imagem
                    }
                );

            }

            setSalvarDisabled(false);

            // Go to home and pass quote id
            navigate("/home", {
                state: {
                    orcamentoId: quoteRef.id
                }
            });

        } catch (error) {
            sendError(ERRO_SALVAR_ORCAMENTO, error);
            navigate("/error/" + ERRO_SALVAR_ORCAMENTO.code);
        }

    }

    const generateQuoteNumber = () => {
        var date = new Date();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        return addZeroBefore(day)
            + addZeroBefore(month)
            + addZeroBefore(hour)
            + addZeroBefore(minute)
            + addZeroBefore(second);
    }

    const addZeroBefore = (value) => {
        return (value < 10 ? "0" : "") + value;
    }

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    };


    const onClienteSearch = (value) => {

        if (!value || value.length < 3) {
            setClienteOptions([]);
            return;
        }

        value = value
            .replace(/\s+/g, " ") // remove extra spaces
            .substring(0, 50)
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        const clienteQuery = query(
            collection(db, "quotes"),
            where("userId", "==", props.user.uid),
            where("nomeClienteSearch", "array-contains", value),
            orderBy("nomeCliente", "asc"),
            limit(10));

        getDocs(clienteQuery)
            .then((response) => {

                let clientes = [];
                response.docs.forEach((doc) => {
                    if (clientes.includes(doc.data().nomeCliente)) {
                        return;
                    }
                    clientes.push(doc.data().nomeCliente);
                });
                setClienteOptions(clientes);

            }).catch((error) => {
                setSnackBarSeverity("error");
                setSnackBarMessage("Falha ao buscar clientes.")
                setSnackBarOpen(true);
            });

    }

    useEffect(() => {

        let valorFinal =
            Number(valorOrcamento)
            - Number(descontoOrcamento)
            + Number(acrescimoOrcamento);

        if (!taxasIncluidas) {
            valorFinal = valorFinal - Number(taxasValor);
        }

        valorFinal = valorFinal.toFixed(2);
        setValorFinal(valorFinal);

    }, [valorOrcamento, descontoOrcamento, acrescimoOrcamento, taxasIncluidas, taxasValor]);

    const viewImage = async (url) => {
        const imageRef = ref(storage, url);
        const downloadURL = await getDownloadURL(imageRef);
        window.open(downloadURL, '_blank');
    };

    const getImageElement = () => {

        if (!imagem) {
            return null;
        }

        if (imagem instanceof File) {
            return imagem.name;
        }

        return <>
            <span style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => viewImage(imagem)}>
                Ver Imagem
            </span>
        </>

    };

    return (
        <div>
            <MenuAppBar />

            <div className="container">

                <Breadcrumbs
                    className="mt-3 mb-3"
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/home">
                        Home
                    </Link>
                    <Typography color="text.primary">Novo Orçamento</Typography>
                </Breadcrumbs>

                <Divider sx={{ borderBottomWidth: 3 }} />

                <Typography className="mt-4" variant="h5">
                    Dados do Cliente
                </Typography>

                {/* Nome do cliente 
                value = quando muda a option
                onChange = quando muda a option

                inputValue = o texto na caixa
                onInputChange = quando muda o texto da caixa
                */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <Autocomplete
                            fullWidth
                            freeSolo
                            id="autcomplete-cliente"
                            value={clienteValue}
                            onChange={(event, newValue) => setClienteValue(newValue)}
                            inputValue={nomeCliente}
                            onInputChange={(event, newInputValue) => {
                                setNomeCliente(newInputValue);
                                onClienteSearch(newInputValue);
                            }}
                            options={clienteOptions}
                            sx={{ minWidth: 250 }}
                            renderInput={(params) =>
                                <TextField {...params} label="Nome do Cliente" size="small" />} />
                    </div>
                </div>

                {/* Número de adultos e crianças */}
                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <TextField
                            size="small"
                            fullWidth
                            required
                            id="numero-adultos"
                            label="Número de adultos"
                            variant="outlined"
                            value={numeroAdultos}
                            onChange={(event) => setNumeroAdultos(event.target.value)} />
                    </div>
                    <div className="col-md-6 col-lg-3 mt-3">
                        <TextField
                            size="small"
                            fullWidth
                            id="numero-criancas"
                            label="Número de crianças"
                            variant="outlined"
                            value={numeroCriancas}
                            onChange={(event) => setNumeroCriancas(event.target.value)} />
                    </div>
                </div>

                {/* Idade das crianças */}
                <div className="row">
                    {
                        (numeroCriancas > 0 ?
                            [...Array(parseInt(numeroCriancas))].map((element, index) => {
                                return <div className="col-md-6 col-lg-3 mt-3" key={index}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id={"idade-crianca-" + index}
                                        label={"Idade criança " + (index + 1)}
                                        variant="outlined"
                                        value={idadeCriancas[index]}
                                        onChange={(event) => {
                                            let idadeCriancasTemp = [...idadeCriancas];
                                            idadeCriancasTemp[index] = event.target.value;
                                            setIdadeCriancas(idadeCriancasTemp);
                                        }} />
                                </div>
                            })
                            : null)
                    }
                </div>

                <div className="row mt-2">
                    <div className="col-lg-6">
                        <hr />
                    </div>
                </div>

                <Typography className="mt-2" variant="h5">
                    Dados da Hospedagem
                </Typography>

                {/* Hospedagens */}
                {
                    hospedagens?.map((element, index) => {
                        return <div key={index}>

                            {/* Destino */}
                            <div className="row">
                                <div className="col-lg-6 mt-3 d-flex align-items-center">
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id={"destino-" + index}
                                        label={"Destino " + (index + 1)}
                                        variant="outlined"
                                        value={hospedagens[index].destino}
                                        onChange={(event) => {
                                            let hospedagensTemp = [...hospedagens];
                                            hospedagensTemp[index].destino = event.target.value;
                                            setHospedagens(hospedagensTemp);
                                        }} />

                                    {// Remover destino 
                                        (index + 1 !== hospedagens.length ?
                                            <Clear className="ms-2" style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    let hospedagensTemp = [...hospedagens];
                                                    hospedagensTemp.splice(index, 1);
                                                    setHospedagens(hospedagensTemp);
                                                }} />
                                            : null)
                                    }
                                </div>
                            </div>

                            {/* Hospedagem */}
                            <div className="row">
                                <div className="col-lg-6 mt-3">
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id={"nome-hospedagem-" + index}
                                        label={"Nome da Hospedagem"}
                                        variant="outlined"
                                        value={hospedagens[index].nome_hospedagem}
                                        onChange={(event) => {
                                            let hospedagensTemp = [...hospedagens];
                                            hospedagensTemp[index].nome_hospedagem = event.target.value;
                                            setHospedagens(hospedagensTemp);
                                        }} />
                                </div>
                            </div>

                            {/* Classificação */}
                            <div className="row">
                                <div className="col-lg-6 mt-3">
                                    <Rating
                                        size="large"
                                        id={"classificacao-" + index}
                                        value={hospedagens[index].classificacao}
                                        onChange={(event, newValue) => {
                                            let hospedagensTemp = [...hospedagens];
                                            hospedagensTemp[index].classificacao = newValue;
                                            setHospedagens(hospedagensTemp);
                                        }} />
                                </div>
                            </div>

                            {/* Datas */}
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <DatePicker
                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                        label="Data Início"
                                        value={
                                            hospedagens[index].dataInicio ?
                                                dayjs(hospedagens[index].dataInicio, 'DD/MM/YYYY') : null
                                        }
                                        onChange={(newValue) => {
                                            let hospedagensTemp = [...hospedagens];
                                            hospedagensTemp[index].dataInicio = newValue ? newValue.format('DD/MM/YYYY') : "";
                                            setHospedagens(hospedagensTemp);
                                        }} />
                                </div>
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <DatePicker
                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                        label="Data Final"
                                        value={hospedagens[index].dataFinal ?
                                            dayjs(hospedagens[index].dataFinal, 'DD/MM/YYYY') : null}
                                        onChange={(newValue) => {
                                            let hospedagensTemp = [...hospedagens];
                                            hospedagensTemp[index].dataFinal = newValue ? newValue.format('DD/MM/YYYY') : "";
                                            setHospedagens(hospedagensTemp);
                                        }} />
                                </div>
                            </div>

                            {/* Tipo acomodação */}
                            <div className="row">
                                <div className="col-lg-6 mt-3">
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id={"tipo-acomodacao-" + index}
                                        label={"Tipo da Acomodação"}
                                        variant="outlined"
                                        value={hospedagens[index].tipo_acomodacao}
                                        onChange={(event) => {
                                            let hospedagensTemp = [...hospedagens];
                                            hospedagensTemp[index].tipo_acomodacao = event.target.value;
                                            setHospedagens(hospedagensTemp);
                                        }} />
                                </div>
                            </div>

                            {/* Regime de alimentação */}
                            <div className="row">
                                <div className="col-lg-6 mt-3">
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id={"regime-alimentacao-" + index}
                                        label={"Regime de Alimentação"}
                                        variant="outlined"
                                        value={hospedagens[index].regime_alimentacao}
                                        onChange={(event) => {
                                            let hospedagensTemp = [...hospedagens];
                                            hospedagensTemp[index].regime_alimentacao = event.target.value;
                                            setHospedagens(hospedagensTemp);
                                        }} />
                                </div>
                            </div>

                            {// Divider
                                (index + 1 !== hospedagens.length ?
                                    <div className="row">
                                        <div className="col-lg-6 mt-3 text-center">
                                            <ArrowDownward />
                                        </div>
                                    </div>
                                    : null)
                            }

                        </div>
                    })
                }

                {/* Adicionar destino */}
                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => {
                                let hospedagensTemp = [...hospedagens];
                                hospedagensTemp.push(hospedagemObj);
                                setHospedagens(hospedagensTemp);
                            }}>
                            Adicionar Novo Destino
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <hr />
                    </div>
                </div>

                <Typography className="mt-2" variant="h5">
                    Tipo de transporte
                </Typography>

                {
                    tiposTransporte?.map((element, index) => {
                        return <div key={index}>

                            {/* Empresa */}
                            <div className="row">
                                <div className="col-lg-6 mt-3 d-flex align-items-center">
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id={"empresa-" + index}
                                        label={"Nome da Cia/Empresa " + (index + 1)}
                                        variant="outlined"
                                        value={tiposTransporte[index].empresa}
                                        onChange={(event) => {
                                            let tiposTransporteTemp = [...tiposTransporte];
                                            tiposTransporteTemp[index].empresa = event.target.value;
                                            setTiposTransporte(tiposTransporteTemp);
                                        }} />

                                    {// Remover voo 
                                        (index + 1 !== tiposTransporte.length ?
                                            <Clear className="ms-2" style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    let tiposTransporteTemp = [...tiposTransporte];
                                                    tiposTransporteTemp.splice(index, 1);
                                                    setTiposTransporte(tiposTransporteTemp);
                                                }} />
                                            : null)
                                    }
                                </div>
                            </div>

                            {/* Datas */}
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <DatePicker
                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                        label="Data Início"
                                        value={
                                            tiposTransporte[index].dataInicio ?
                                                dayjs(tiposTransporte[index].dataInicio, 'DD/MM/YYYY') : null
                                        }
                                        onChange={(newValue) => {
                                            let tiposTransporteTemp = [...tiposTransporte];
                                            tiposTransporteTemp[index].dataInicio = newValue ? newValue.format('DD/MM/YYYY') : "";
                                            tiposTransporteTemp[index].dataFinal = newValue ? newValue.format('DD/MM/YYYY') : "";
                                            setTiposTransporte(tiposTransporteTemp);
                                        }} />
                                </div>
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <DatePicker
                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                        label="Data Final"
                                        value={tiposTransporte[index].dataFinal ?
                                            dayjs(tiposTransporte[index].dataFinal, 'DD/MM/YYYY') : null}
                                        onChange={(newValue) => {
                                            let tiposTransporteTemp = [...tiposTransporte];
                                            tiposTransporteTemp[index].dataFinal = newValue ? newValue.format('DD/MM/YYYY') : "";
                                            setTiposTransporte(tiposTransporteTemp);
                                        }} />
                                </div>
                            </div>

                            {/* Origem/Destino */}
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        id={"origem-" + index}
                                        inputValue={tiposTransporte[index].origem}
                                        onInputChange={(event, newInputValue) => {
                                            let tiposTransporteTemp = [...tiposTransporte];
                                            tiposTransporteTemp[index].origem = newInputValue;
                                            setTiposTransporte(tiposTransporteTemp);
                                        }}
                                        options={AEROPORTOS}
                                        renderInput={(params) =>
                                            <TextField {...params} label="Origem" size="small" />} />
                                </div>
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <Autocomplete
                                        fullWidth
                                        freeSolo
                                        id={"destino-" + index}
                                        inputValue={tiposTransporte[index].destino}
                                        onInputChange={(event, newInputValue) => {
                                            let tiposTransporteTemp = [...tiposTransporte];
                                            tiposTransporteTemp[index].destino = newInputValue;
                                            setTiposTransporte(tiposTransporteTemp);
                                        }}
                                        options={AEROPORTOS}
                                        renderInput={(params) =>
                                            <TextField {...params} label="Destino" size="small" />} />
                                </div>
                            </div>

                            {/* Partida/Chegada */}
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <TimeField
                                        size="small"
                                        fullWidth
                                        label={"Partida"}
                                        format="HH:mm"
                                        value={
                                            tiposTransporte[index].partida ?
                                                dayjs(tiposTransporte[index].partida, 'HH:mm') : null
                                        }
                                        onChange={(newValue) => {
                                            let tiposTransporteTemp = [...tiposTransporte];
                                            tiposTransporteTemp[index].partida = newValue ? newValue.format('HH:mm') : "";
                                            setTiposTransporte(tiposTransporteTemp);
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <TimeField
                                        size="small"
                                        fullWidth
                                        label={"Chegada"}
                                        format="HH:mm"
                                        value={
                                            tiposTransporte[index].chegada ?
                                                dayjs(tiposTransporte[index].chegada, 'HH:mm') : null
                                        }
                                        onChange={(newValue) => {
                                            let tiposTransporteTemp = [...tiposTransporte];
                                            tiposTransporteTemp[index].chegada = newValue ? newValue.format('HH:mm') : "";
                                            setTiposTransporte(tiposTransporteTemp);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Bagagem */}
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <FormControlLabel
                                        label="Bagagem de mão (10kg)"
                                        control={<Checkbox
                                            size="small"
                                            checked={tiposTransporte[index].bagagem_mao}
                                            onChange={(event) => {
                                                let tiposTransporteTemp = [...tiposTransporte];
                                                tiposTransporteTemp[index].bagagem_mao = event.target.checked;
                                                setTiposTransporte(tiposTransporteTemp);
                                            }} />}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-3 mt-3">
                                    <FormControlLabel
                                        label="Bagagem despachada (23kg)"
                                        control={<Checkbox
                                            size="small"
                                            checked={tiposTransporte[index].bagagem_despachada}
                                            onChange={(event) => {
                                                let tiposTransporteTemp = [...tiposTransporte];
                                                tiposTransporteTemp[index].bagagem_despachada = event.target.checked;
                                                setTiposTransporte(tiposTransporteTemp);
                                            }} />}
                                    />
                                </div>
                            </div>

                            {// Divider
                                (index + 1 !== tiposTransporte.length ?
                                    <div className="row">
                                        <div className="col-lg-6 text-center">
                                            <ArrowDownward />
                                        </div>
                                    </div>
                                    : null)
                            }

                        </div>
                    })
                }

                {/* Adicionar voo */}
                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => {

                                let tiposTransporteTemp = [...tiposTransporte];
                                tiposTransporteTemp.push(tipoTransporteObj);

                                // Copia dados do voo anterior
                                let tipoTransporteNovo = tiposTransporteTemp.at(-1);
                                let tipoTransporteAnterior = tiposTransporteTemp.at(-2);
                                tipoTransporteNovo.empresa = tipoTransporteAnterior.empresa;
                                tipoTransporteNovo.origem = tipoTransporteAnterior.destino;
                                tipoTransporteNovo.destino = tipoTransporteAnterior.origem;
                                tipoTransporteNovo.bagagem_mao = tipoTransporteAnterior.bagagem_mao;
                                tipoTransporteNovo.bagagem_despachada = tipoTransporteAnterior.bagagem_despachada;

                                setTiposTransporte(tiposTransporteTemp);

                            }}>
                            Adicionar Voo
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <hr />
                    </div>
                </div>

                <Typography className="mt-2" variant="h5">
                    Serviços
                </Typography>

                {
                    servicos?.map((element, index) => {
                        return <div key={index}>

                            <div className="row">
                                <div className="col-lg-6 mt-3 d-flex align-items-center">
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id={"servico-" + index}
                                        label={"Serviço " + (index + 1)}
                                        variant="outlined"
                                        value={servicos[index]}
                                        onChange={(event) => {
                                            let servicosTemp = [...servicos];
                                            servicosTemp[index] = event.target.value;
                                            setServicos(servicosTemp);
                                        }} />

                                    {// Remover serviço 
                                        (index + 1 !== servicos.length ?
                                            <Clear className="ms-2" style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    let servicosTemp = [...servicos];
                                                    servicosTemp.splice(index, 1);
                                                    setServicos(servicosTemp);
                                                }} />
                                            : null)
                                    }
                                </div>
                            </div>

                        </div>
                    })
                }
                {/* Adicionar serviço */}
                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => {
                                let servicosTemp = [...servicos];
                                servicosTemp.push("");
                                setServicos(servicosTemp);
                            }}>
                            Adicionar Serviço
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <hr />
                    </div>
                </div>

                <Typography className="mt-2" variant="h5">
                    Observações
                </Typography>

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <ReactQuill
                            theme="snow"
                            value={observacoes}
                            onChange={setObservacoes} />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-lg-6">
                        <hr />
                    </div>
                </div>

                <Typography className="mt-2" variant="h5">
                    Valores
                </Typography>

                <div className="row">
                    <div className="col-lg-3 mt-3">
                        <TextField
                            fullWidth
                            size="small"
                            id="valor-orcamento"
                            label="Valor do Orçamento"
                            value={valorOrcamento}
                            onChange={(event) => {
                                let value = event.target.value.replace(/[^0-9]/g, '');
                                value = (Number(value) / 100).toFixed(2);
                                setValorOrcamento(value);
                            }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 mt-3">
                        <TextField
                            fullWidth
                            size="small"
                            id="desconto-orcamento"
                            label="Desconto"
                            value={descontoOrcamento}
                            onChange={(event) => {
                                let value = event.target.value.replace(/[^0-9]/g, '');
                                value = (Number(value) / 100).toFixed(2);
                                setDescontoOrcamento(value);
                            }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 mt-3">
                        <TextField
                            fullWidth
                            size="small"
                            id="acrescimo-orcamento"
                            label="Acréscimo"
                            value={acrescimoOrcamento}
                            onChange={(event) => {
                                let value = event.target.value.replace(/[^0-9]/g, '');
                                value = (Number(value) / 100).toFixed(2);
                                setAcrescimoOrcamento(value);
                            }} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 mt-3">
                        <TextField
                            fullWidth
                            size="small"
                            id="valor-final-orcamento"
                            label="Valor Final Orçamento"
                            value={valorFinalOrcamento}
                            readOnly />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            size="small"
                            fullWidth
                            id="forma-pagamento"
                            label="Forma de Pagamento"
                            variant="outlined"
                            value={formaPagamento}
                            onChange={(event) => setFormaPagamento(event.target.value)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <TextField
                            size="small"
                            fullWidth
                            id="taxas"
                            label="Taxas"
                            variant="outlined"
                            value={taxasValor}
                            onChange={(event) => {
                                let value = event.target.value.replace(/[^0-9]/g, '');
                                value = (Number(value) / 100).toFixed(2);
                                setTaxasValor(value);
                            }} />
                    </div>
                    <div className="col-md-6 col-lg-3 mt-3">
                        <FormControlLabel
                            label="Taxas Incluídas"
                            control={<Checkbox
                                size="small"
                                checked={taxasIncluidas}
                                onChange={(event) => setTaxasIncluidas(event.target.checked)} />}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-lg-6">
                        <hr />
                    </div>
                </div>

                <Typography className="mt-2" variant="h5">
                    Configurações
                </Typography>

                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <FormControl fullWidth size="small" >
                            <InputLabel id="select-situacao">Situação</InputLabel>
                            <Select
                                labelId="select-situacao"
                                id="select-situacao"
                                value={situacaoOrcamento}
                                label="Situação"
                                onChange={(event) => setSituacaoOrcamento(event.target.value)}>
                                <MenuItem value={"Novo"}>Novo</MenuItem>
                                <MenuItem value={"Aprovado"}>Aprovado</MenuItem>
                                <MenuItem value={"Rejeitado"}>Rejeitado</MenuItem>
                                <MenuItem value={"Expirado"}>Expirado</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <DateTimePicker
                            slotProps={{ textField: { fullWidth: true, size: "small" } }}
                            label="Expira em"
                            value={dataExpiracao}
                            onChange={(newValue) => setDataExpiracao(newValue)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <Button
                            variant="text"
                            component="label">
                            Escolher Imagem
                            <input hidden
                                accept="image/jpeg, image/png"
                                type="file"
                                onChange={(event) => {
                                    setImagem(event.target.files[0]);
                                }} />
                        </Button>
                    </div>
                    <div className="col-md-6 col-lg-3 mt-3 d-flex align-items-center">
                        <div style={{ minWidth: "0px" }}> {/* https://css-tricks.com/flexbox-truncated-text/ */}
                            <Typography variant="body2" className="text-truncate">
                                {
                                    getImageElement()
                                }
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-6">
                        <hr />
                    </div>
                </div>

                {/* Botão salvar */}
                <div className="row">
                    <div className="col-md-6 col-lg-3"></div>
                    <div className="col-md-6 col-lg-3 mt-3">
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={salvarDisabled}
                            onClick={() => onSave()}>
                            Salvar
                        </Button>
                    </div>
                </div>

                {
                    // Botão salvar cópia
                    (modeRef.current == "edit") ?
                        <div className="row">
                            <div className="col-md-6 col-lg-3"></div>
                            <div className="col-md-6 col-lg-3 mt-3 mb-3">
                                <Button
                                    fullWidth
                                    variant="text"
                                    disabled={salvarDisabled}
                                    onClick={() => {
                                        modeRef.current = "new";
                                        onSave();
                                    }}>
                                    Salvar Cópia
                                </Button>
                            </div>
                        </div>
                        : null
                }

                {/* Snackbar */}
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackBarClose}
                    TransitionComponent={Fade}>
                    <Alert onClose={handleSnackBarClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>

            </div>

            <Footer />

        </div >
    );
}

export default NewQuote;