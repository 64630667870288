import { useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Flight } from '@mui/icons-material';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { ERRO_FAZER_LOGOFF, sendError } from "../Errors";

export default function MenuAppBar() {

    const navigate = useNavigate();

    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            sendError(ERRO_FAZER_LOGOFF, error);
            navigate("/error/" + ERRO_FAZER_LOGOFF.code);
        });
    };

    return (
        <Box sx={{ flexGrow: 1 }}>

            <AppBar position="static">

                <Toolbar>

                    <div className="container">
                        <div className="row">

                            <div className="col-6 mt-2">
                                <img src="/images/logo.png" height="30" />
                            </div>

                            <div className="col-6 col-sm-6 d-flex justify-content-end">
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit">
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}>
                                    <MenuItem onClick={() => navigate("/profile")}>Minha conta</MenuItem>
                                    <MenuItem onClick={() => navigate("/subscription")}>Assinatura</MenuItem>
                                    <MenuItem onClick={() => handleSignOut()}>Sair</MenuItem>
                                </Menu>
                            </div>

                        </div>
                    </div>

                </Toolbar>

            </AppBar>

        </Box>
    );
}