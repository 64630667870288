import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Alert } from '@mui/material';
import Copyright from "./Copyright";
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { ERRO_CRIAR_CONTA, getFirebaseError, sendError } from "../Errors";
import ReactGA from "react-ga4";

function CreateAccount() {

    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const navigate = useNavigate();
    const db = getFirestore(getApp());
    const auth = getAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = data.get('email');
        let password = data.get('password');
        let error = false;

        if (!email) {
            setEmailError("Informe o e-mail");
            error = true;
        }

        if (!password) {
            setPasswordError("Informe a senha");
            error = true;
        }

        if (error) {
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                return setDoc(doc(db, "users", user.uid),
                    {
                        "email": user.email,
                        "nomeAgencia": "",
                        "telefoneContato": "",
                        "enderecoCompleto": "",
                        "mensagemRodape": "Orçamento sujeito à alteração de valores e disponibilidade sem aviso prévio. Somente a reserva e pagamento garantem o valor e disponibilidade. Faça sua reserva.",
                        "logo": null,
                        "stripeUserId": ""
                    }
                );
            }).then(() => {
                navigate("/home");
                ReactGA.event('conversion', { 'send_to': 'AW-985673392/wvYfCIDXttwYELDdgNYD' });
            }).catch((error) => {

                let errorMessage = getFirebaseError(error);
                if (errorMessage) {
                    setAlertMessage(errorMessage);
                    setAlertSeverity("error");
                } else {
                    sendError(ERRO_CRIAR_CONTA, error);
                    navigate("/error/" + ERRO_CRIAR_CONTA.code);
                }

            });
    };

    const onEmailChange = (value) => {
        if (value) {
            setEmailError("");
            setAlertMessage("");
        }
    }

    const onPasswordChange = (value) => {
        if (value) {
            setPasswordError("");
            setAlertMessage("");
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Criar Conta
                </Typography>

                {alertMessage ?
                    < Alert
                        severity={alertSeverity}
                        sx={{ width: 1 }}>
                        {alertMessage}
                    </Alert>
                    : null
                }

                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                {// Conditional attributes
                                ...(emailError && {
                                    error: true,
                                    helperText: emailError
                                })}
                                onChange={(ev) => onEmailChange(ev.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                {// Conditional attributes
                                ...(passwordError && {
                                    error: true,
                                    helperText: passwordError
                                })}
                                onChange={(ev) => onPasswordChange(ev.target.value)}
                            />
                        </Grid>
                        {/*
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>
                        */}
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Avançar
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Já tem uma conta? Faça login
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}

export default CreateAccount;
