import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Copyright from "./Copyright";
import { useNavigate } from 'react-router-dom';
import { ERRO_ENVIAR_SENHA, getFirebaseError, sendError } from "../Errors";
import { ERRO_FAZER_LOGIN } from "../Errors";

function Login() {

    const [email, setEmail] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [resetPassDialogOpen, setResetPassDialogOpen] = useState(false);

    const auth = getAuth();
    auth.languageCode = 'pt_br';
    const navigate = useNavigate();

    const handleSubmit = (event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = data.get('email');
        let password = data.get('password');
        let error = false;

        if (!email) {
            setEmailError("Informe o e-mail");
            error = true;
        }

        if (!password) {
            setPasswordError("Informe a senha");
            error = true;
        }

        if (error) {
            return;
        }

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log("Signed in", user);
                navigate("/home");
            })
            .catch((error) => {

                let errorMessage = getFirebaseError(error);
                if (errorMessage) {
                    setAlertMessage(errorMessage);
                    setAlertSeverity("error");
                } else {
                    sendError(ERRO_FAZER_LOGIN, error);
                    navigate("/error/" + ERRO_FAZER_LOGIN.code);
                }

            });
    };

    const onEmailChange = (value) => {
        setEmail(value);
        if (value) {
            setEmailError("");
            setAlertMessage("");
        }
    }

    const onPasswordChange = (value) => {
        if (value) {
            setPasswordError("");
            setAlertMessage("");
        }
    }

    const handlePassDialogOpen = (ev) => {
        ev.preventDefault();
        if (!email) {
            setEmailError("Informe o e-mail");
            return;
        }
        setResetPassDialogOpen(true);
    };

    const handlePassDialogClose = (sendEmail) => {

        setResetPassDialogOpen(false);

        if (!sendEmail) {
            return;
        }

        sendPasswordResetEmail(auth, email)
            .then(() => {
                setAlertMessage("E-mail de redefinição enviado com sucesso");
                setAlertSeverity("success");
            })
            .catch((error) => {

                let errorMessage = getFirebaseError(error);
                if (errorMessage) {
                    setAlertMessage(errorMessage);
                    setAlertSeverity("error");
                } else {
                    sendError(ERRO_ENVIAR_SENHA, error);
                    navigate("/error/" + ERRO_ENVIAR_SENHA.code);
                }

            });

    };

    return (
        <div>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        //backgroundImage: 'url(https://source.unsplash.com/random)',
                        backgroundImage: 'url("/images/login.jpg")',
                        //backgroundImage: `url(${LoginImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
                            Fazer Login
                        </Typography>

                        {alertMessage ?
                            < Alert
                                severity={alertSeverity}
                                sx={{ width: 1 }}>
                                {alertMessage}
                            </Alert>
                            : null
                        }

                        <Box component="form" noValidate onSubmit={handleSubmit} >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                {// Conditional attributes
                                ...(emailError && {
                                    error: true,
                                    helperText: emailError
                                })}
                                onChange={(ev) => onEmailChange(ev.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                {// Conditional attributes
                                ...(passwordError && {
                                    error: true,
                                    helperText: passwordError
                                })}
                                onChange={(ev) => onPasswordChange(ev.target.value)}
                            />
                            {/*
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Avançar
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link
                                        href="#"
                                        variant="body2"
                                        onClick={(ev) => handlePassDialogOpen(ev)}>
                                        Esqueceu a senha?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/createaccount" variant="body2">
                                        {"Não tem uma conta? Cadastre-se"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* Dialog para enviar e-mail de redefinição */}
            <Dialog
                open={resetPassDialogOpen}
                onClose={handlePassDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Redefinir Senha"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Um e-mail para resetar a senha será enviado para {email}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handlePassDialogClose(true)} autoFocus>
                        Enviar
                    </Button>
                    <Button onClick={() => handlePassDialogClose(false)}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Login;
