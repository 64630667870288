import { Alert, AlertTitle, Breadcrumbs, Button, Collapse, Divider, Link, Typography } from "@mui/material";
import MenuAppBar from "./MenuAppBar";
import { NavigateNext } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import { ERRO_CRIAR_ASSINATURA, ERRO_GERENCIAR_ASSINATURA, ERRO_OBTER_ASSINATURA, sendError } from "../Errors";

function Subscription(props) {

    const [alertMessageOpen, setAlertMessageOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");

    const [subscription, setSubscription] = useState(null);

    const navigate = useNavigate();
    const functions = getFunctions(getApp(), "southamerica-east1");

    useEffect(() => {

        // Get subscription
        const getSubscription = httpsCallable(functions, 'getSubscription');
        getSubscription()
            .then((result) => {
                setSubscription(result.data.subscription);
            })
            .catch((error) => {
                sendError(ERRO_OBTER_ASSINATURA, error);
                navigate("/error/" + ERRO_OBTER_ASSINATURA.code);
            });

        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setAlertMessageOpen(true);
            setAlertSeverity("success");
            setAlertMessage("Assinatura do plano realizada com sucesso!");
        }

        if (query.get('canceled')) {
            setAlertMessageOpen(true);
            setAlertSeverity("error");
            setAlertMessage("Pedido de assinatura cancelado.");
        }

    }, []);

    const onSubscribe = () => {

        const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
        createCheckoutSession()
            .then((result) => {
                let redirectUrl = result.data.redirect_url;
                window.open(redirectUrl, "_self");
            })
            .catch((error) => {
                sendError(ERRO_CRIAR_ASSINATURA, error);
                navigate("/error/" + ERRO_CRIAR_ASSINATURA.code);
            });

    };

    const onManageSubscription = () => {

        const createPortalSession = httpsCallable(functions, 'createPortalSession');
        createPortalSession()
            .then((result) => {
                let redirectUrl = result.data.redirect_url;
                window.open(redirectUrl, "_blank");
            })
            .catch((error) => {
                sendError(ERRO_GERENCIAR_ASSINATURA, error);
                navigate("/error/" + ERRO_GERENCIAR_ASSINATURA.code);
            });

    };

    return (
        <div>
            <MenuAppBar />

            <div className="container mb-auto">

                <Breadcrumbs
                    className="mt-3"
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/home">
                        Home
                    </Link>
                    <Typography color="text.primary">Assinatura</Typography>
                </Breadcrumbs>

                <Divider className="mt-3" sx={{ borderBottomWidth: 3 }} />

                <Typography className="mt-3" variant="h5">
                    Dados da assinatura
                </Typography>

                <Collapse in={alertMessageOpen}>
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <Alert severity={alertSeverity}>
                                {alertTitle ?
                                    <AlertTitle>{alertTitle}</AlertTitle>
                                    : null}
                                {alertMessage}
                            </Alert>
                        </div>
                    </div>
                </Collapse>

                <div className="row">
                    {(subscription) ?
                        <div>
                            <Typography className="mt-3">
                                Você possui uma assinatura ativa.
                            </Typography>
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mt-3 mb-3">
                                    <Button
                                        color="success"
                                        variant="outlined"
                                        onClick={() => onManageSubscription()}>
                                        Gerenciar assinatura
                                    </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <Typography className="mt-3">
                                Você não tem uma assinatura ativa.
                            </Typography>
                            <div className="row">
                                <div className="col-md-6 col-lg-3 mt-3 mb-3">
                                    <Button
                                        color="success"
                                        variant="outlined"
                                        onClick={() => onSubscribe()}>
                                        Clique aqui para assinar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>

            <div className="fixed-bottom">
                <Footer />
            </div>

        </div>
    );
}

export default Subscription;