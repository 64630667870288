import * as Sentry from "@sentry/react";

export function sendError(internalError, error) {

    console.log("error", error);
    
    if (!error) {
        error = new AppError(internalError.message);
    }

    Sentry.captureException(error,
        (scope) => {
            //scope.setTransactionName(internalError.code);
            scope.setContext("Internal Error", internalError);
            return scope;
        }
    );
}

export class AppError extends Error {
    constructor(message) {
        super(message);
        this.name = "AppError";
    }
}

export function getFirebaseError(error) {

    if (!error || !error.code)
        return null;

    switch (error.code) {
        case "auth/user-not-found":
            return "Usuário não encontrado.";
        case "auth/email-already-in-use":
            return "E-mail já está em uso.";
        case "auth/invalid-email":
            return "E-mail inválido.";
        case "auth/requires-recent-login.":
            return "Operação requer re-autenticação.";
        case "auth/user-token-expired":
            return "Acesso do usuário expidado.";
        case "auth/wrong-password":
            return "Senha incorreta.";
        case "auth/weak-password":
            return "Senha deve ter pelo menos 6 caracteres.";
        default:
            return null;
    }

}

export const ERRO_OBTER_ASSINATURA = {
    code: "VC3L",
    message: "Erro ao obter dados da assinatura."
};

export const ERRO_DADOS_USUARIO_NAO_EXISTEM = {
    code: "KHHS",
    message: "Documento com dados do usuário não foi encontrado."
};

export const ERRO_OBTER_DADOS_USUARIO = {
    code: "ZKID",
    message: "Erro ao obter documento com dados do usuário."
}

export const ERRO_FAZER_LOGIN = {
    code: "6A60",
    message: "Erro ao fazer login."
};

export const ERRO_ENVIAR_SENHA = {
    code: "JR1E",
    message: "Erro ao enviar senha."
};

export const ERRO_CRIAR_CONTA = {
    code: "7FTO",
    message: "Erro ao criar conta."
};

export const ERRO_OBTER_ORCAMENTOS = {
    code: "I4RL",
    message: "Erro ao obter orçamentos."
};

export const ERRO_EXCLUIR_ORCAMENTO = {
    code: "KPMV",
    message: "Erro ao excluir orçamento."
};

export const ERRO_FAZER_LOGOFF = {
    code: "YFBL",
    message: "Erro ao fazer logoff."
};

export const ERRO_SALVAR_ORCAMENTO = {
    code: "J0DG",
    message: "Erro ao salvar orçamento."
};

export const ERRO_REAUTENTICAR = {
    code: "YVBI",
    message: "Erro ao reautenticar."
};

export const ERRO_DELETAR_CONTA = {
    code: "6I5P",
    message: "Erro ao deletar conta."
};

export const ERRO_SALVAR_PERFIL = {
    code: "ERD7",
    message: "Erro ao salvar perfil."
};

export const ERRO_CRIAR_ASSINATURA = {
    code: "25EY",
    message: "Erro ao criar assinatura."
};

export const ERRO_GERENCIAR_ASSINATURA = {
    code: "UMIR",
    message: "Erro ao gerenciar assinatura."
};

export const ERRO_CARREGAR_ORCAMENTO = {
    code: "9XFX",
    message: "Erro ao carregar orçamento."
};

export const ERRO_VISUALIZAR_ORCAMENTO = {
    code: "XTYD",
    message: "Erro ao visualizar orçamento."
};

/*
X9GF
WFBD
6C9Y
*/