import { useNavigate, useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { useEffect, useState } from "react";
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faPlaneDeparture, faPlaneArrival, faPlane, faLocationPin, faCircleCheck, faCircleXmark, faCalendarDay, faPersonWalkingLuggage, faClock, faCalendarDays, faBed, faStar, faHotel, faUtensils, faCircle, faCheck, faCircleInfo, faTicket, faBellConcierge, faEarthAmerica, faDollarSign, faUser, faPhone, faEnvelope, faLocationDot, faMinus, faPlus, faHandHoldingDollar, faDotCircle, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import Footer from './Footer';
import dayjs from 'dayjs';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { ERRO_VISUALIZAR_ORCAMENTO, sendError } from '../Errors';
import { MoreVert } from '@mui/icons-material';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css';

function ViewQuote(props) {

  let { quoteId } = useParams();
  const [quote, setQuote] = useState({
    "numeroCriancas": "",
    "numeroAdultos": "",
    "orcamentoId": "",
    "valorOrcamento": "",
    "descontoOrcamento": "",
    "userId": "",
    "dataExpiracao": null,
    "nomeCliente": "",
    "hospedagens": [],
    "idadeCriancas": [],
    "tiposTransporte": [],
    "observacoes": "",
    "taxasIncluidas": false,
    "taxasValor": "",
    "situacaoOrcamento": "",
    "dataOrcamentoString": "",
    "servicos": [],
    "dataOrcamento": null,
    "formaPagamento": "",
    "valorFinalOrcamento": "",
    "numeroOrcamento": ""
  });
  const [logoSrc, setLogoSrc] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [user, setUser] = useState({
    "email": "",
    "enderecoCompleto": "",
    "nomeAgencia": "",
    "telefoneContato": ""
  });

  const [status, setStatus] = useState("loading");

  const navigate = useNavigate();
  const storage = getStorage();
  const db = getFirestore(getApp());

  useEffect(() => {

    const docRef = doc(db, "quotes", quoteId);
    getDoc(docRef)
      .then((docSnap) => {

        if (docSnap.exists()) {

          let quote = docSnap.data();
          console.log("quote", quote);
          setQuote(quote);
          if (quote.situacaoOrcamento == "Expirado") {
            setStatus("expired");
          } else {
            setStatus("ok");
          }

        } else {
          setStatus("not-found");
        }

      }).catch((error) => {
        sendError(ERRO_VISUALIZAR_ORCAMENTO, error);
        navigate("/error/" + ERRO_VISUALIZAR_ORCAMENTO.code);
      });

  }, [quoteId]);

  useEffect(() => {

    if (!quote.userId) {
      return;
    }

    const userDataRef = doc(db, "users", quote.userId);
    getDoc(userDataRef)
      .then((docSnap) => {
        console.log("user", docSnap.data());
        setUser(docSnap.data());
      }).catch((error) => {
        sendError(ERRO_VISUALIZAR_ORCAMENTO, error);
        navigate("/error/" + ERRO_VISUALIZAR_ORCAMENTO.code);
      });

  }, [quote]);

  useEffect(() => {
    getImagem();
  }, [quote]);

  useEffect(() => {
    getLogo();
  }, [user]);

  const getImagem = () => {

    if (!quote.imagem) {
      return;
    }

    const imageRef = ref(storage, quote.imagem);
    getDownloadURL(imageRef)
      .then((url) => {
        setImageSrc(url);
      });

  };

  const getLogo = () => {

    if (!user.logo) {
      return;
    }

    const logoRef = ref(storage, user.logo);
    getDownloadURL(logoRef)
      .then((url) => {
        setLogoSrc(url);
      });

  };

  const getPeriodo = () => {

    // Tem hospedagem, então calcula noites
    let hospedagemDataInicio = quote.hospedagens.at(0)?.dataInicio;
    let hospedagemDataFinal = quote.hospedagens.at(-1)?.dataFinal;
    let noites = dayjs(hospedagemDataFinal, 'DD/MM/YYYY').diff(dayjs(hospedagemDataInicio, 'DD/MM/YYYY'), 'day');

    // Não tem voo, então retorna só as noites
    if (!temVoos()) {
      return `${noites} noite${noites === 1 ? '' : 's'}`;
    }

    // Tem voos, então calcula dias
    let transporteDataInicio = quote.tiposTransporte.at(0)?.dataInicio;
    let transporteDataFinal = quote.tiposTransporte.at(-1)?.dataFinal;
    let dias = dayjs(transporteDataFinal, 'DD/MM/YYYY').diff(dayjs(transporteDataInicio, 'DD/MM/YYYY'), 'day') + 1;

    // Tem os dois, então retorna tudo
    return `${dias} dia${dias === 1 ? '' : 's'}, ${noites} noite${noites === 1 ? '' : 's'} `;

  };


  const getNumeroPessoas = () => {

    let numeroAdultos = parseInt(quote.numeroAdultos ?? 0);
    let numeroCriancas = parseInt(quote.numeroCriancas ?? 0);
    let idadeCriancas = quote.idadeCriancas;
    let resultado = "";

    if (numeroAdultos > 0) {
      resultado += "Valor para " + numeroAdultos + " adulto" + (numeroAdultos == 1 ? "" : "s");
    }

    if (numeroCriancas > 0) {
      resultado += " e " + numeroCriancas + " criança" + (numeroCriancas == 1 ? "" : "s");
    }

    if (idadeCriancas.length > 0) {
      let idadeUltimaCrianca = parseInt(idadeCriancas.at(-1));
      resultado += " (" + quote.idadeCriancas.join(", ") + " ano" + (idadeUltimaCrianca == 1 ? "" : "s") + ")";
    }

    return resultado;

  };

  const getDataExpiracao = () => {

    if (!quote.dataExpiracao) {
      return "";
    }

    let dataExpiracaoTs = quote.dataExpiracao;
    let dataDayJs = dayjs(dataExpiracaoTs.toDate());
    return dataDayJs.format('DD/MM/YYYY HH:mm:ss');

  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(num);
  };

  const getTaxasEmbarque = () => {

    if (quote.taxasIncluidas) {
      return "Todas as taxas incluídas";
    }

    return "Taxas no valor de "
      + formatNumber(quote.taxasValor)
      + " não incluídas";

  };

  const temVoos = () => {
    for (let i = 0; i < quote.tiposTransporte.length; i++) {
      if (quote.tiposTransporte[i].empresa.length > 0) {
        return true;
      }
    }
    return false;
  };

  const temHospedagem = () => {
    for (let i = 0; i < quote.hospedagens.length; i++) {
      if (quote.hospedagens[i].destino.length > 0) {
        return true;
      }
    }
    return false;
  };

  const temServicos = () => {
    for (let i = 0; i < quote.servicos.length; i++) {
      if (quote.servicos[i].length > 0) {
        return true;
      }
    }
    return false;
  };

  const temObservacoes = () => {
    return quote.observacoes.length > 0;
  };

  const generatePage = () => {

    if (status == "loading") {
      return <div className="d-flex justify-content-center p-3">
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </div>;
    }

    if (status == "expired") {
      return <div className="container">
        <div className='container text-start mt-4'>
          <Typography variant="h4">
            {"Ops :("}
          </Typography>
          <div className="col-md-6 mt-3">
            <Alert severity="error">{'Orçamento ' + quote.numeroOrcamento + ' expirado.'}</Alert>
          </div>
          <p className='mt-3'>Seu orçamento pode ter novas condições.</p>
          <p>Entre em contato para atualizar seu orçamento.</p>
          <button
            type="button"
            className='btn btn-outline-success mt-2 mb-2 mb-md-4 pe-4 ps-4 pb-2 d-print-none'
            onClick={() => {
              let url = "https://wa.me/55"
                + user.telefoneContato.replace(/\D/g, "")
                + "?text=Ol%C3%A1!%0AMeu%20or%C3%A7amento%20est%C3%A1%20expirado.%0AGostaria%20de%20verificar%20as%20novas%20condi%C3%A7%C3%B5es.%0A"
                + "Orçamento%20" + quote.numeroOrcamento + "(" + quote.nomeCliente + ").";
              console.log("url", url)
              window.open(url, "_blank");
            }}>
            Entrar em contato<FontAwesomeIcon icon={faWhatsapp} className='ms-2' />
          </button>
        </div>
      </div>;
    }

    if (status == "not-found") {
      return <div className="container">
        <div className='container text-start mt-4'>
          <Typography variant="h4">
            {"Que pena!"}
          </Typography>
          <div className="col-md-6 mt-3">
            <Alert severity="error">Orçamento não encontrado.</Alert>
          </div>
          <p className='mt-3'>O orçamento que você está tentando visualizar não existe mais.</p>
          <p>Entre em contato com seu agente de viagens.</p>
        </div>
      </div>;
    }

    return <div className='overflow-hidden mt-5'>

      {/* Navbar */}
      <nav className='navbar navbar-expand navbar-light bg-light fixed-top d-print-none border-bottom'>
        <div className='container-fluid d-flex justify-content-center'>
          <ul className='navbar-nav flex-nowrap overflow-auto'>
            <li className='nav-item'>
              <a className='nav-link px-3' href="#orcamento">Orçamento</a>
            </li>
            {
              temVoos() ?
                <li className='nav-item'>
                  <a className='nav-link px-3' href="#itinerario">Itinerário</a>
                </li> : null
            }
            {
              temHospedagem() ?
                <li className='nav-item'>
                  <a className='nav-link px-3' href="#hospedagem">Hospedagem</a>
                </li> : null
            }
            {
              temServicos() ?
                <li className='nav-item'>
                  <a className='nav-link px-3' href="#servicos">Serviços</a>
                </li>
                : null
            }
            {
              temObservacoes() ?
                <li className='nav-item'>
                  <a className='nav-link px-3' href="#info">Informações</a>
                </li>
                : null
            }
            <li className='nav-item'>
              <a className='nav-link px-3' href="#valores">Valores</a>
            </li>
          </ul>
        </div>
      </nav>

      {/* Recebe o logo e trata o tamanho mantendo a proporção */}
      <div className='d-flex justify-content-center'>
        {
          logoSrc ?
            <img src={logoSrc} className='rounded mt-4' width="auto" max-width="120" height="120" max-height="auto" alt="Logo"></img>
            : null
        }
      </div>

      <div id='orcamento' className='text-center mt-4' style={{ 'scrollMarginTop': "4em" }}> <h5>{'Orçamento ' + quote.numeroOrcamento}</h5></div>
      <div className='text-center mt-1 text-capitalize'> <p>{quote.nomeCliente}</p></div>

      {/* Informações da viagem */}
      <div className='container w-100'>
        <div className='row text-center'>

          {
            // Destino
            temHospedagem() ?
              <div className='col-12 mb-2'>
                <span><FontAwesomeIcon icon={faLocationDot} style={{ 'color': "crimson" }} /><small className='ms-2'>Destino:</small>
                  <strong className='ms-2 text-uppercase'>
                    {
                      quote.hospedagens.map((element) => element.destino).join(", ")
                    }
                  </strong>
                </span>
              </div> : null
          }

          {
            // Período
            temHospedagem() ?
              <div className='col-12 mb-2'>
                <span><FontAwesomeIcon icon={faCalendar} style={{ 'color': "slategray" }} /><small className='ms-2'>Período:</small>
                  <strong className='ms-2 text-uppercase'>
                    {getPeriodo()}
                  </strong>
                </span>
              </div>
              : null
          }

          {
            // Embarque
            temVoos() ?
              <div className='col-12'>
                <span><FontAwesomeIcon icon={faCalendarDay} style={{ 'color': "slategray" }} /><small className='ms-2'>Embarque:</small>
                  <strong className='ms-2 text-uppercase'>
                    {quote.tiposTransporte.at(0)?.dataInicio}
                  </strong>
                </span>
              </div>
              : null
          }


        </div>
      </div>

      {
        // Imagem 
        <div className='d-flex justify-content-center me-2 ms-2'>
          {
            imageSrc ?
              <img src={imageSrc} className='img-fluid rounded mt-4' style={{ 'max-height': "250px" }} alt="Destino"></img>
              : null
          }
        </div>
      }

      {
        // Itinerário
        temVoos() ?
          <>
            <div id='itinerario' className='container mt-4' style={{ 'scrollMarginTop': "4em" }}>
              <div className='row'>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
                <div className='col col-lg-2 col-md-4 d-flex justify-content-center'>
                  <FontAwesomeIcon icon={faEarthAmerica} style={{ 'color': "dodgerblue" }} className='me-2 mt-2' size='lg' />
                  <h5 className='mt-1'>Itinerário</h5>
                </div>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
              </div>
            </div>

            <div className='container'>
              {
                quote.tiposTransporte?.map((voo, index) => {
                  return <div key={index}>

                    {/* Operadora */}
                    <div className='row text-center mb-2'>
                      <div className='col'>
                        <p>
                          <FontAwesomeIcon icon={faPlane} className='me-2' size='sm' />
                          <small>Operado por </small><small className='text-capitalize'>{voo.empresa}</small>
                        </p>
                      </div>
                    </div>

                    {/* Origem / Destino */}
                    <div className='row justify-content-center text-center'>
                      <div className='col col-md-4 col-sm-6'>
                        <span>
                          <small>
                            <FontAwesomeIcon icon={faLocationDot} style={{ 'color': "crimson" }} className='me-2' />
                            <strong>{voo.origem}</strong>
                          </small>
                        </span>
                      </div>

                      <div className='col col-md-1 d-none d-md-block'>
                        <FontAwesomeIcon icon={faPlane} style={{ 'color': "dodgerblue" }} className='mb-0' />
                      </div>

                      <div className='col col-md-4 col-sm-6'>
                        <span>
                          <small>
                            <FontAwesomeIcon icon={faLocationDot} style={{ 'color': "crimson" }} className='me-2' />
                            <strong>{voo.destino}</strong>
                          </small>
                        </span>
                      </div>
                    </div>

                    {/* Data início e final */}
                    <div className='row justify-content-center text-center mt-1'>
                      <div className='col col-md-4 col-sm-6'>
                        <span>
                          <small>
                            <FontAwesomeIcon icon={faCalendarDay} style={{ 'color': "slategray" }} className='me-2' />
                            <strong>{voo.dataInicio}</strong>
                          </small>
                        </span>
                      </div>

                      <div className='col col-md-1 d-none d-md-block'>
                        <FontAwesomeIcon icon={faCalendarDays} style={{ 'color': "dodgerblue" }} className='mb-0' />
                      </div>

                      <div className='col col-md-4 col-sm-6'>
                        <span>
                          <small>
                            <FontAwesomeIcon icon={faCalendarDay} style={{ 'color': "slategray" }} className='me-2' />
                            <strong>{voo.dataFinal}</strong>
                          </small>
                        </span>
                      </div>
                    </div>

                    {/* Horário de partida e chegada */}
                    <div className='row justify-content-center text-center mt-1'>
                      <div className='col col-md-4 col-sm-6'>
                        <span>
                          <small>
                            <FontAwesomeIcon icon={faPlaneDeparture} style={{ 'color': "dodgerblue" }} className='me-2' />
                            <strong>{voo.partida}</strong>
                          </small>
                        </span>
                      </div>

                      <div className='col col-md-1 d-none d-md-block'><FontAwesomeIcon icon={faClock} style={{ 'color': "dodgerblue" }} className='mb-0' />
                      </div>

                      <div className='col col-md-4 col-sm-6'>
                        <span>
                          <small>
                            <FontAwesomeIcon icon={faPlaneArrival} style={{ 'color': "dodgerblue" }} className='me-2' />
                            <strong>{voo.chegada}</strong>
                          </small>
                        </span>
                      </div>

                    </div>

                    {/* Bagagem */}
                    <div className='w-100 mt-4 mt-lg-4'>
                      <div className='row d-flex justify-content-center'>

                        <small className='text-center mb-2'>
                          <FontAwesomeIcon icon={faPersonWalkingLuggage} className='mt-1 me-2' />
                          Bagagem
                        </small>

                        <span className='col-10 col-lg-4 col-md-5 border rounded text-center'>
                          <div className='mt-2'>
                            <span>
                              <FontAwesomeIcon
                                icon={voo.bagagem_mao ? faCircleCheck : faCircleXmark}
                                style={{ 'color': voo.bagagem_mao ? "limegreen" : "crimson" }} />
                              <small className='ms-1'>Bagagem de mão (10kg)</small>
                            </span>
                          </div>
                          <div className='mt-2 mb-2'>
                            <span><FontAwesomeIcon
                              icon={voo.bagagem_despachada ? faCircleCheck : faCircleXmark}
                              style={{ 'color': voo.bagagem_despachada ? "limegreen" : "crimson" }} />
                              <small className='ms-1'>Bagagem despachada (23kg)</small>
                            </span>
                          </div>
                        </span>

                      </div>
                    </div>

                    {// Divider
                      (index + 1 !== quote.tiposTransporte.length ?
                        <div className="row">
                          <div className="text-center mt-2 mb-2">
                            <MoreVert />
                          </div>
                        </div>
                        : null)
                    }

                  </div>
                })
              }
            </div>
          </>
          : null
      }

      {
        // Hospedagem
        temHospedagem() ?
          <>
            <div id='hospedagem' className='container mt-4' style={{ 'scrollMarginTop': "4em" }}>
              <div className='row'>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
                <div className='col col-lg-2 col-md-4 d-flex justify-content-center'>
                  <FontAwesomeIcon icon={faBellConcierge} style={{ 'color': "lightslategrey" }} className='me-2 mt-2' size='lg' />
                  <h5 className='mt-1'>Hospedagem</h5>
                </div>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
              </div>
            </div>

            <div className='container mt-2'>
              {
                quote.hospedagens?.map((hospedagem, index) => {
                  return <div key={index}>

                    <div className='row text-center'>

                      <div className='col-12 mt-1'>
                        <span>
                          <strong className='text-uppercase'>
                            {hospedagem.nome_hospedagem}
                          </strong>
                        </span>
                      </div>

                      {
                        hospedagem.classificacao > 0 ?
                          <div className='col-12 mt-0 mb-1'>
                            <span>
                              <FontAwesomeIcon icon={faStar} style={{ 'color': hospedagem.classificacao >= 1 ? "gold" : "lightgrey" }} className='me-2' size='xs' />
                              <FontAwesomeIcon icon={faStar} style={{ 'color': hospedagem.classificacao >= 2 ? "gold" : "lightgrey" }} className='me-2' size='xs' />
                              <FontAwesomeIcon icon={faStar} style={{ 'color': hospedagem.classificacao >= 3 ? "gold" : "lightgrey" }} className='me-2' size='xs' />
                              <FontAwesomeIcon icon={faStar} style={{ 'color': hospedagem.classificacao >= 4 ? "gold" : "lightgrey" }} className='me-2' size='xs' />
                              <FontAwesomeIcon icon={faStar} style={{ 'color': hospedagem.classificacao >= 5 ? "gold" : "lightgrey" }} className='me-2' size='xs' />
                            </span>
                          </div>
                          : null
                      }

                      <div className='col-12 mt-1 mb-1'>
                        <span>
                          <FontAwesomeIcon icon={faCalendarDay} style={{ 'color': "slategray" }} className='me-2' />
                          <span>De</span>
                          <strong className='ms-2 me-2 text-uppercase'>
                            {hospedagem.dataInicio}
                          </strong>
                          <span>até</span>
                          <strong className='ms-2 text-uppercase'>
                            {hospedagem.dataFinal}
                          </strong>
                        </span>
                      </div>

                      {
                        hospedagem.tipo_acomodacao ?
                          <div className='col-12 mt-1 mb-1 text-capitalize'>
                            <span>
                              <FontAwesomeIcon icon={faBed} style={{ 'color': "royalblue" }} className='me-2' />
                              {hospedagem.tipo_acomodacao}
                            </span>
                          </div>
                          : null
                      }

                      <div className='col-12 mt-1 text-capitalize'>
                        <span>
                          <FontAwesomeIcon icon={faUtensils} style={{ 'color': "lightslategrey" }} className='me-2' />
                          {hospedagem.regime_alimentacao}
                        </span>
                      </div>

                      {// Divider
                        (index + 1 !== quote.hospedagens.length ?
                          <div className="row">
                            <div className="text-center mt-2 mb-2">
                              <MoreVert />
                            </div>
                          </div>
                          : null)
                      }

                    </div>

                  </div>
                })
              }
            </div>
          </>
          : null
      }

      {
        /* Serviços */
        temServicos() ?
          <>
            <div id='servicos' className='container mt-4' style={{ 'scrollMarginTop': "4em" }}>
              <div className='row'>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
                <div className='col col-lg-2 col-md-4 d-flex justify-content-center'>
                  <FontAwesomeIcon icon={faTicket} style={{ 'color': "crimson" }} className='me-2 mt-2' size='lg' />
                  <h5 className='mt-1'>Serviços</h5>
                </div>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
              </div>
            </div>

            <div className='container d-flex justify-content-center text-capitalize mt-3'>
              <div className='row'>
                <div className='col-12 lh-1'>
                  {
                    quote.servicos?.map((servico, index) => {
                      return <div key={index}>
                        <p>
                          <FontAwesomeIcon icon={faCheck} style={{ 'color': "limegreen" }} className='me-2' size='xs' />
                          {servico}
                        </p>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          </>
          : null
      }

      {
        // Informações
        temObservacoes() ?
          <>
            <div id='info' className='container mt-3' style={{ 'scrollMarginTop': "4em" }}>
              <div className='row'>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
                <div className='col col-lg-2 col-md-4 d-flex justify-content-center'>
                  <FontAwesomeIcon icon={faCircleInfo} style={{ 'color': "goldenrod" }} className='me-2 mt-2' size='lg' />
                  <h5 className='mt-1'>Informações</h5>
                </div>
                <div className='col col-lg-5 col-md-4'>
                  <hr></hr>
                </div>
              </div>
            </div>

            <div className='container text-lg-start w-100'>
              <div className='row justify-content-center'>
                <div className='col-lg-8'>
                  <ReactQuill
                    value={quote.observacoes}
                    readOnly={true}
                    theme="bubble" />
                </div>
              </div>
            </div>

          </>
          : null
      }

      {/* Valores */}
      <div id='valores' className='container' style={{ 'scrollMarginTop': "4em" }}>
        <div className='row'>
          <div className='col col-lg-5 col-md-4'>
            <hr></hr>
          </div>
          <div className='col col-lg-2 col-md-4 d-flex justify-content-center'>
            <FontAwesomeIcon icon={faDollarSign} style={{ 'color': "forestgreen" }} className='me-2 mt-2' size='lg' />
            <h5 className='mt-1'>Valores</h5>
          </div>
          <div className='col col-lg-5 col-md-4'>
            <hr></hr>
          </div>
        </div>
      </div>

      {/* Dados dos valores */}
      <div className='container'>
        <div className='row text-center'>
          <div className='col-12 mt-1'>
            <span><small className='fw-bold'>{getNumeroPessoas()}</small></span>
          </div>
        </div>
      </div>
      <div className='container d-flex justify-content-center mt-3 mb-3'>
        <div className='row col-12 col-lg-10 border rounded p-2'>
          <div className='col-lg-6 col-md-6 col-sm-12 ps-2 ps-lg-4 pe-2 mt-2 mt-md-4'>
            <div className='row small'>
              <div className='col-7'>
                <FontAwesomeIcon icon={faHandHoldingDollar} style={{ 'color': "lightslategrey" }} className='me-2' size='lg' />
                Valor do orçamento
              </div>
              <div className='col-5 text-end'>
                {formatNumber(quote.valorOrcamento)}
              </div>
            </div>
            <div className='row mt-1 small'>
              <div className='col-7'>
                <FontAwesomeIcon icon={faMinus} style={{ 'color': "lightslategrey" }} className='me-2' size='lg' />
                Descontos
              </div>
              <div className='col-5 text-end'>
                {formatNumber(quote.descontoOrcamento)}
              </div>
            </div>
            <div className='row mt-1 small'>
              <div className='col-7'>
                <FontAwesomeIcon icon={faPlus} style={{ 'color': "lightslategrey" }} className='me-2' size='lg' />
                Acréscimos
              </div>
              <div className='col-5 text-end'>
                {formatNumber(quote.acrescimoOrcamento)}
              </div>
            </div>
            <hr className='mb-2 mt-2'></hr>
            <div className='row mt-0'>
              <div className='col-7'>
                <strong>
                  <FontAwesomeIcon icon={faDollarSign} style={{ 'color': "forestgreen" }} className='me-2' size='lg' />
                  Total a pagar
                </strong>
              </div>
              <div className='col-5 text-end'>
                <strong>
                  {formatNumber(quote.valorFinalOrcamento)}
                </strong>
              </div>
            </div>
            <div className='row mt-0 mb-3'>
              <div className='col text-center text-sm-end mt-2 mt-sm-0' style={{ fontSize: "0.7em" }}>
                {getTaxasEmbarque()}
              </div>
            </div>
            <p className='mb-0 text-center text-md-start ms-md-2 mb-1' style={{ fontSize: "0.8em" }}>
              <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ 'color': "lightslategrey" }} className='me-2' size='lg' />
              Forma de pagamento:
            </p>
            <div className='container border rounded p-2 mb-3'>
              <div className='row'>
                {
                  quote.formaPagamento.split(";").map((formaPagamento, index) => {
                    return <div key={index}>
                      <div className='col' style={{ fontSize: "0.8em" }}>
                        <FontAwesomeIcon icon={faCheck} style={{ 'color': "lightslategrey" }} className='me-2' size='sm' />
                        {formaPagamento.trim()}
                      </div>
                    </div>
                  })
                }
              </div>
            </div>
          </div>
          <div className='col-lg-1 col-md-1 d-flex justify-content-center p-0'>
            <hr className='vr d-none d-md-block'></hr>
          </div>
          <div className='col-lg-5 col-md-5 col-sm-12 text-start ps-lg-0 mt-3 mt-md-3'>
            <strong>Faça sua reserva</strong>
            <p className='mb-0 mt-2'>
              <FontAwesomeIcon icon={faUser} style={{ 'color': "lightslategrey" }} className='me-2' size='xs' />
              {user.nomeAgencia}
            </p>
            <p className='mb-0 mt-1'>
              <FontAwesomeIcon icon={faPhone} style={{ 'color': "lightslategrey" }} className='me-2' size='xs' />
              {user.telefoneContato}
            </p>
            <p className='mb-0 mt-1'>
              <FontAwesomeIcon icon={faEnvelope} style={{ 'color': "lightslategrey" }} className='me-2' size='xs' />
              {user.email}
            </p>
            <p className='mb-2 mt-1 text-truncate'>
              <FontAwesomeIcon icon={faLocationPin} style={{ 'color': "lightslategrey" }} className='me-2' size='xs' />
              <a href={"https://maps.google.com/?q=" + user.enderecoCompleto}
                className='text-decoration-none'
                style={{ 'color': "#212529", 'whiteSpace': "pre-line" }}
                target='_blank'>
                {user.enderecoCompleto}
              </a>
            </p>
            <button
              type="button"
              className='btn btn-outline-success mt-2 mb-2 mb-md-4 pe-4 ps-4 pb-2 d-print-none'
              onClick={() => {
                let url = "https://wa.me/55"
                  + user.telefoneContato.replace(/\D/g, "")
                  + "?text=Ol%C3%A1!%0AGostaria%20de%20falar%20sobre%20o%20or%C3%A7amento%20"
                  + quote.numeroOrcamento + "(" + quote.nomeCliente + ").";
                console.log("url", url)
                window.open(url, "_blank");
              }}>
              Entrar em contato<FontAwesomeIcon icon={faWhatsapp} className='ms-2' />
            </button>
          </div>
        </div>
      </div>
      <div className='row mb-3 d-flex justify-content-center'>
        <div className='col-10 text-center'>
          <p className='text-muted mb-1' style={{ 'fontSize': "0.7em" }}>Orçamento válido até {getDataExpiracao()}</p>
          <p className='text-muted' style={{ 'fontSize': "0.7em" }}>{user.mensagemRodape}</p>
        </div>
      </div>

      <Footer />
    </div >
  }

  return (generatePage());

}



export default ViewQuote;