import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import CreateAccount from "./pages/CreateAccount";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Home from "./pages/Home";
import NewQuote from "./pages/NewQuote";
import ViewQuote from "./pages/ViewQuote";
import Profile from "./pages/Profile";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import ErrorPage from "./pages/Error";
import { ERRO_DADOS_USUARIO_NAO_EXISTEM, ERRO_OBTER_ASSINATURA, ERRO_OBTER_DADOS_USUARIO, sendError } from "./Errors";
import { getAnalytics } from "firebase/analytics";
import * as Sentry from "@sentry/react";
import Subscription from "./pages/Subscription";

function App() {

  const navigate = useNavigate();

  // Initialize Firebase
  const firebaseConfig = {
    apiKey: "AIzaSyB2WL2fy2tCiS0jpxX-HzAc5CzM6BYkOx0",
    authDomain: "travels-web-app.firebaseapp.com",
    projectId: "travels-web-app",
    storageBucket: "travels-web-app.appspot.com",
    messagingSenderId: "888475052299",
    appId: "1:888475052299:web:558bb09e9dc7b08a979a0e",
    measurementId: "G-LKYGC1ZF99"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  auth.languageCode = 'pt_br';
  const functions = getFunctions(app, "southamerica-east1");

  const [authState, setAuthState] = useState("pending");
  const [user, setUser] = useState(null);

  const DIAS_PERIODO_TESTE = 7;
  const [diasRestantesTeste, setDiasRestantesTeste] = useState(null);

  useEffect(() => {
    let unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthState("signedIn");
        setUser(user);
        Sentry.setUser(user);
        console.log("signedIn");
      } else {
        setAuthState("signedOut");
        setUser(null);
        Sentry.setUser(null);
        console.log("signedOut");
      }
    });
    return unsub;
  }, []);

  useEffect(() => {

    if (user?.uid) {

      // Verifica se está em período de testes e calcula os dias
      let diasUso = dateDiff(Date.parse(user.metadata.creationTime), Date.now());
      console.log("diasUso", diasUso);

      if (diasUso <= DIAS_PERIODO_TESTE) {
        
        let diasRestantesTeste = DIAS_PERIODO_TESTE - diasUso;
        setDiasRestantesTeste(diasRestantesTeste);

      } else {

        // Verifica a situação da assinatura da conta
        const getSubscription = httpsCallable(functions, 'getSubscription');
        getSubscription()
          .then((result) => {

            if (!result.data.subscription) {
              console.log("no subscription found");
              navigate("/subscription");
            }

          })
          .catch((error) => {
            console.log("error", error)
            sendError(ERRO_OBTER_ASSINATURA, error);
            navigate("/error/" + ERRO_OBTER_ASSINATURA.code);
          });

      }

    }

  }, [user]);

  const dateDiff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  };

  // Waiting to get auth state
  if (authState === "pending") {
    return (
      <div className="d-flex justify-content-center p-3">
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </div>
    );
  }

  const RedirectSite = () => {
    window.location.href = "/site";
    return <></>;
  };

  return (
    <Routes>

      {authState === "signedIn" &&
        <Route>
          <Route path="/" element={<RedirectSite />} />
          <Route path="/home" element={<Home user={user} diasRestantesTeste={diasRestantesTeste} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/newquote/:quoteId?" element={<NewQuote user={user} />} />
          <Route path="/subscription" element={<Subscription user={user} />} />
        </Route>
      }

      <Route path="/" element={<RedirectSite />} />
      <Route path="/home" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/createaccount" element={<CreateAccount />} />
      <Route path="/viewquote/:quoteId" element={<ViewQuote />} />
      <Route path="/error/:errorCode" element={<ErrorPage />} />
    </Routes>
  );

}

export default App;
