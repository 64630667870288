import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MenuAppBar from './MenuAppBar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, AlertTitle, Autocomplete, Box, Button, Card, CardActions, CardContent, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField } from '@mui/material';
import { Add, Delete, Edit, SentimentDissatisfied, Share } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, doc, deleteDoc, orderBy, limit, startAfter } from "firebase/firestore";
import { getApp } from "firebase/app";
import { ERRO_EXCLUIR_ORCAMENTO, ERRO_OBTER_ORCAMENTOS, sendError } from '../Errors';
import './Styles.css';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Footer from './Footer';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faListNumeric } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Home(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const db = getFirestore(getApp());

    const [alertMessageOpen, setAlertMessageOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertAction, setAlertAction] = useState(null);

    const [quotes, setQuotes] = useState([]);
    const [lastQuoteVisible, setLastQuoteVisible] = useState(null);
    const [deleteQuote, setDeleteQuote] = useState({});
    const [quoteURL, setQuoteURL] = useState("");
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    // Data states
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dataDialogOpen, setDataDialogOpen] = useState(false);
    const [dataDias, setDataDias] = useState(null);
    const [dataStringValue, setDataStringValue] = useState("");
    const [dataInicial, setDataInicial] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [dataErro, setDataErro] = useState(false);
    const [dataErroMensagem, setDataErroMensagem] = useState("");

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState("success");
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [numeroOrcamento, setNumeroOrcamento] = useState("");
    const [clienteOptions, setClienteOptions] = useState([]);
    const [clienteValue, setClienteValue] = useState("");
    const [clienteSelected, setClienteSelected] = useState(null);
    const [situacaoOrcamento, setSituacaoOrcamento] = useState("");

    useEffect(() => {

        if (props.diasRestantesTeste != null) {
            setAlertTitle("Teste Grátis");
            setAlertSeverity("info");
            let alertMessage = "";
            switch (props.diasRestantesTeste) {
                case 0:
                    alertMessage = "Seu período de testes termina hoje. Assine para continuar usando todas as funcionalidades do Travels."
                    break;
                case 1:
                    alertMessage = "Você tem mais um dia para experimentar todas as funcionalidades do Travels."
                    break;
                default:
                    alertMessage = "Você ainda tem " + props.diasRestantesTeste + " dias para experimentar todas as funcionalidades do Travels."
            }
            setAlertMessage(alertMessage);
            setAlertMessageOpen(true);
        }

    }, [props]);

    useEffect(() => {

        if (!location.state) {
            return;
        }

        let orcamentoId = location.state.orcamentoId;
        if (orcamentoId) {
            window.scrollTo(0, 0);
            setAlertTitle("");
            setAlertMessage("Orçamento salvo com sucesso.");
            setAlertSeverity("success");
            setAlertMessageOpen(true);
            setAlertAction(
                <Button color="inherit"
                    size="small" onClick={() => {
                        let link = process.env.REACT_APP_VIEW_QUOTE_URL + orcamentoId;
                        navigator.clipboard.writeText(link);
                        setAlertAction(null);
                        setAlertMessage("Link copiado.");
                    }}>
                    Copiar Link
                </Button>
            );
        }

        // Clear state
        window.history.replaceState({}, document.title);

    }, [location.state]);

    useEffect(() => {
        searchQuotes();
    }, [numeroOrcamento,
        clienteSelected,
        situacaoOrcamento,
        dataDias,
        // trigger manually
        //dataInicial,
        //dataFinal
    ]);

    const getQuotesQuery = () => {

        let quotesQuery = query(
            collection(db, "quotes"),
            where("userId", "==", props.user.uid),
            limit(10));

        if (numeroOrcamento) {
            quotesQuery = query(quotesQuery,
                where("numeroOrcamento", "==", numeroOrcamento));
        }

        if (clienteSelected) {
            quotesQuery = query(quotesQuery,
                where("nomeCliente", "==", clienteSelected));
        }

        if (situacaoOrcamento) {
            quotesQuery = query(quotesQuery,
                where("situacaoOrcamento", "==", situacaoOrcamento));
        }

        if (dataDias) {

            const dataDiasInicial = dayjs().subtract(dataDias, 'day').endOf('day').toDate();
            const dataDiasFinal = dayjs().endOf('day').toDate();

            quotesQuery = query(quotesQuery,
                where("dataOrcamento", ">=", dataDiasInicial),
                where("dataOrcamento", "<=", dataDiasFinal));

        }

        if (dataInicial && dataFinal) {

            console.log("dataInicial" + dataInicial.startOf('day').toDate())
            console.log("dataFinal" + dataFinal.endOf('day').toDate())

            quotesQuery = query(quotesQuery,
                where("dataOrcamento", ">=", dataInicial.startOf('day').toDate()),
                where("dataOrcamento", "<=", dataFinal.endOf('day').toDate()));

        }

        quotesQuery = query(quotesQuery,
            orderBy("dataOrcamento", "desc"));

        return quotesQuery;

    }

    const searchQuotes = () => {

        let quotesQuery = getQuotesQuery();

        getDocs(quotesQuery)
            .then((response) => {

                // Push data to array
                let resposeQuotes = [];
                response.docs.forEach((doc) =>
                    resposeQuotes.push(doc.data())
                );
                setQuotes(resposeQuotes);

                // Get the last visible document
                if (response.docs.length > 0) {
                    const lastQuoteVisible = response.docs[response.docs.length - 1];
                    setLastQuoteVisible(lastQuoteVisible);
                }

            }).catch((error) => {
                sendError(ERRO_OBTER_ORCAMENTOS, error);
                navigate("/error/" + ERRO_OBTER_ORCAMENTOS.code);
            });
    };

    const fetchMoreQuotes = () => {

        let quotesQuery = getQuotesQuery();

        // Search after last quote visible
        quotesQuery = query(quotesQuery, startAfter(lastQuoteVisible));

        getDocs(quotesQuery)
            .then((response) => {

                if (response.docs.length == 0) {
                    return;
                }

                // Push data to array
                let resposeQuotes = [...quotes];
                response.docs.forEach((doc) =>
                    resposeQuotes.push(doc.data())
                );

                setQuotes(resposeQuotes);

                // Get the last visible document
                if (response.docs.length > 0) {
                    const lastQuoteVisible = response.docs[response.docs.length - 1];
                    setLastQuoteVisible(lastQuoteVisible);
                }

            }).catch((error) => {
                sendError(ERRO_OBTER_ORCAMENTOS, error);
                navigate("/error/" + ERRO_OBTER_ORCAMENTOS.code);
            });

    };

    const getStatusColor = (status) => {
        if (status === "Novo") return 'primary';
        if (status === "Aprovado") return 'success';
        if (status === "Rejeitado") return 'error';
        if (status === "Expirado") return 'default';
        return 'default';
    };

    const showShareQuoteDialog = (quote) => {
        setQuoteURL(process.env.REACT_APP_VIEW_QUOTE_URL + quote.orcamentoId);
        setShareDialogOpen(true);
    };

    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    };

    const showDeleteDialog = (quote) => {
        setDeleteQuote(quote);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleDateDialogClose = () => {
        setDataDialogOpen(false);
    };

    const handleDeleteQuote = () => {
        deleteDoc(doc(db, "quotes", deleteQuote.orcamentoId))
            .then((response) => {
                setSnackBarSeverity("success");
                setSnackBarMessage("Orçamento excluído com sucesso.")
                setSnackBarOpen(true);
                searchQuotes();
                setDeleteDialogOpen(false);
            }).catch((error) => {
                sendError(ERRO_EXCLUIR_ORCAMENTO, error);
                navigate("/error/" + ERRO_EXCLUIR_ORCAMENTO.code);
            });
    };

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    };

    const handleScroll = (e) => {
        const bottom = e.scrollTop + e.clientHeight == e.scrollHeight;
        if (bottom) {
            fetchMoreQuotes();
        }
    }

    const onClienteSearch = (value) => {

        if (!value || value.length < 3) {
            setClienteOptions([]);
            return;
        }

        value = value
            .replace(/\s+/g, " ") // remove extra spaces
            .substring(0, 50)
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        const clienteQuery = query(
            collection(db, "quotes"),
            where("userId", "==", props.user.uid),
            where("nomeClienteSearch", "array-contains", value),
            orderBy("nomeCliente", "asc"),
            limit(10));

        getDocs(clienteQuery)
            .then((response) => {

                let clientes = [];
                response.docs.forEach((doc) => {
                    if (clientes.includes(doc.data().nomeCliente)) {
                        return;
                    }
                    clientes.push(doc.data().nomeCliente);
                });
                setClienteOptions(clientes);

            }).catch((error) => {
                console.log("Error onClienteSearch", error);
            });
    }

    const handleDataDias = (dias) => {

        setDataInicial(null);
        setDataFinal(null);
        setDataErro(false);
        setDataErroMensagem("");

        setDataDias(dias);
        setDataDialogOpen(false);
        setDataStringValue("Últimos " + dias + " dias");
        searchQuotes();

    }

    const handleLimparData = () => {

        setDataInicial(null);
        setDataFinal(null);
        setDataErro(false);
        setDataErroMensagem("");
        setDataDias(null);
        setDataDialogOpen(false);
        setDataStringValue("");
        searchQuotes();

    };

    const handleAplicarFiltroData = () => {

        setDataErro(false);
        setDataErroMensagem("");

        if (!dataInicial || !dataFinal) {
            setDataErro(true);
            setDataErroMensagem("Informe as datas inicial e final.");
            return;
        }

        if (dataInicial > dataFinal) {
            setDataErro(true);
            setDataErroMensagem("Data inicial deve ser antes da final.");
            return;
        }

        const diferencaDias = dateDiff(dataInicial, dataFinal);
        if (diferencaDias > 90) {
            setDataErro(true);
            setDataErroMensagem("Período não pode ser maior que 90 dias.");
            return;
        }

        setDataStringValue(dataInicial.format('DD/MM/YYYY') + " - " + dataFinal.format('DD/MM/YYYY'));
        setDataDialogOpen(false);
        searchQuotes();

    };

    const dateDiff = (first, second) => {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    };

    // Chart
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        //aspectRatio: 1,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    boxHeight: 5
                }
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        scales: {

            x: {
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                    drawTicks: false
                },
            },
            y: {
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                    drawTicks: false,
                },
            },
        },
    };

    const labels = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Gerados',
                data: [10, 5, 8, 11, 16, 4, 2],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                tension: 0.1,
                borderWidth: 2,
                pointRadius: 1

            },
            {
                label: 'Aprovados',
                data: [2, 6, 7, 1, 6, 2, 1],
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                tension: 0.1,
                borderWidth: 2,
                pointRadius: 1
            },
        ],
    };

    return (
        <div>
            <MenuAppBar />

            <div className="container">

                <Collapse in={alertMessageOpen}>
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <Alert
                                severity={alertSeverity}
                                action={alertAction}
                                onClose={() => setAlertMessageOpen(false)}>
                                {alertTitle ?
                                    <AlertTitle>{alertTitle}</AlertTitle>
                                    : null}
                                {alertMessage}
                            </Alert>
                        </div>
                    </div>
                </Collapse>

                <div className="mt-4 row">

                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0">
                        <Typography
                            variant="h6">
                            Meus Orçamentos
                        </Typography>
                    </div>

                    <div className="col-12 col-sm-6 d-flex justify-content-sm-end">
                        <Button
                            className="flex-grow-1 flex-sm-grow-0"
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => navigate("/newquote")}>
                            Novo Orçamento
                        </Button>
                    </div>

                </div>

                <div className="row mt-3">

                    <div className="d-flex flex-wrap" style={{ gap: "10px" }}>

                        <div className="flex-grow-1 flex-sm-grow-0">
                            <TextField
                                fullWidth
                                id="numero-orcamento"
                                label="#"
                                variant="outlined"
                                color="secondary"
                                size="small"
                                value={numeroOrcamento}
                                onChange={(event) => setNumeroOrcamento(event.target.value)} />
                        </div>

                        <div className="flex-grow-1 flex-sm-grow-0">
                            <Autocomplete
                                fullWidth
                                freeSolo
                                id="autcomplete-cliente"
                                size="small"
                                value={clienteSelected}
                                onChange={(event, newValue) => setClienteSelected(newValue)}
                                inputValue={clienteValue}
                                onInputChange={(event, newInputValue) => {
                                    setClienteValue(newInputValue);
                                    onClienteSearch(newInputValue);
                                }}
                                options={clienteOptions}
                                sx={{ minWidth: 250 }}
                                renderInput={(params) =>
                                    <TextField {...params} color="secondary" label="Cliente" />} />

                        </div>

                        <div className="d-none d-sm-block">
                            <TextField
                                sx={{ minWidth: "220px" }}
                                placeholder="Data"
                                variant="outlined"
                                size="small"
                                value={dataStringValue}
                                readOnly
                                color="secondary"
                                onClick={() => setDataDialogOpen(true)} />
                        </div>

                        <div className="d-none d-sm-block">
                            <FormControl sx={{ minWidth: 120 }} size="small" color="secondary">
                                <InputLabel id="select-situacao">Situação</InputLabel>
                                <Select
                                    labelId="select-situacao"
                                    id="select-situacao"
                                    value={situacaoOrcamento}
                                    label="Situação"
                                    onChange={(event) => setSituacaoOrcamento(event.target.value)}>
                                    <MenuItem value={""}>&nbsp;</MenuItem>
                                    <MenuItem value={"Novo"}>Novo</MenuItem>
                                    <MenuItem value={"Aprovado"}>Aprovado</MenuItem>
                                    <MenuItem value={"Rejeitado"}>Rejeitado</MenuItem>
                                    <MenuItem value={"Expirado"}>Expirado</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                    </div>

                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        {quotes.length > 0 ?
                            <TableContainer component={Paper} sx={{ height: '70vh' }} className="thinScrollBar" onScroll={(e) => handleScroll(e.target)}>
                                <Table>
                                    <TableHead style={{ background: "white", position: "sticky", top: "0", zIndex: "100" }}>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Cliente</TableCell>
                                            <TableCell className="d-none d-sm-table-cell">Data</TableCell>
                                            <TableCell className="d-none d-sm-table-cell">Situação</TableCell>
                                            <TableCell align="right" className="d-sm-table-cell">Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {quotes.length === 0 && (<TableRow><TableCell colSpan={6}>Nenhum orçamento encontrado.</TableCell></TableRow>)} */}
                                        {quotes.map((quote, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    <span style={{ textDecoration: "underline", cursor: "pointer" }}
                                                        onClick={() => navigate("/newquote/" + quote.orcamentoId)}>
                                                        {quote.numeroOrcamento}
                                                    </span>
                                                </TableCell>
                                                <TableCell>{quote.nomeCliente}</TableCell>
                                                <TableCell className="d-none d-sm-table-cell">{quote.dataOrcamentoString}</TableCell>
                                                <TableCell className="d-none d-sm-table-cell">
                                                    <Chip
                                                        label={quote.situacaoOrcamento}
                                                        size="small"
                                                        variant="outlined"
                                                        color={getStatusColor(quote.situacaoOrcamento)} />
                                                </TableCell>
                                                <TableCell width="20%" align="right">
                                                    <IconButton
                                                        color="primary" aria-label="compartilhar" className="p-0"
                                                        onClick={() => { showShareQuoteDialog(quote) }}>
                                                        <Share />
                                                    </IconButton>
                                                    <IconButton
                                                        color="success" aria-label="editar" className="d-none d-sm-inline p-0 ps-2"
                                                        onClick={() => { navigate("/newquote/" + quote.orcamentoId) }}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error" aria-label="deletar" className="d-none d-sm-inline p-0 ps-2"
                                                        onClick={() => { showDeleteDialog(quote) }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <Box sx={{ height: '70vh', boxShadow: 2, padding: "30px", textAlign: "center" }}>
                                <SentimentDissatisfied sx={{ fontSize: "50px", color: "#1976d2" }} />
                                <br />
                                Que pena! Você ainda não fez nenhum orçamento.<br />
                                Que tal <a href='/newquote'>clicar aqui</a> e começar agora?
                            </Box>
                        }
                    </div>
                </div>

                {/* Charts 
                <div className="row mt-3">
                    <div className="col-md-3">
                        <Card >                           
                            <CardContent>
                                <Typography variant="h6" color="text.secondary">
                                    Orçamentos
                                </Typography>
                                <div sx={{ height: "150px" }}>
                                    <Line options={options} data={data} />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            */}
                {/* Dialog compartilhar orçamento */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={shareDialogOpen}
                    onClose={handleShareDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Compartilhar Orçamento
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className="d-flex">
                                <TextField
                                    className="flex-fill"
                                    id="orcamento-link"
                                    label=""
                                    variant="outlined"
                                    size="small"
                                    value={quoteURL}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    onFocus={event => {
                                        event.target.select();
                                    }} />
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        navigator.clipboard.writeText(quoteURL);
                                        setSnackBarMessage("Link copiado.");
                                        setSnackBarOpen(true);
                                    }} >
                                    Copiar
                                </Button>
                            </div>
                            <div className="mt-3">
                                Qualquer pessoa com o link poderá ter acesso ao orçamento.
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleShareDialogClose}>Fechar</Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog excluir orçamento */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description">
                    <DialogTitle id="delete-dialog-title">
                        Excluir Orçamento
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            Tem certeza que deseja excluir o orçamento #{deleteQuote.numeroOrcamento}?
                            <br />
                            Esta ação não poderá ser revertida.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="error" onClick={handleDeleteQuote}>Excluir</Button>
                        <Button onClick={handleDeleteDialogClose}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog filtrar data */}
                <Dialog
                    open={dataDialogOpen}
                    onClose={handleDateDialogClose}>
                    <DialogTitle>
                        <div className="d-flex">
                            <span className="flex-grow-1">Filtrar Data</span>
                            <Button onClick={handleLimparData}>Limpar</Button>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Stack direction="row" spacing={1}>
                            <Chip label="7 dias" onClick={() => handleDataDias(7)} />
                            <Chip label="15 dias" onClick={() => handleDataDias(15)} />
                            <Chip label="30 dias" onClick={() => handleDataDias(30)} />
                            <Chip label="60 dias" onClick={() => handleDataDias(60)} />
                            <Chip label="90 dias" onClick={() => handleDataDias(90)} />
                        </Stack>

                        <div className="mt-4">
                            <DatePicker
                                label="Data Inicial"
                                value={dataInicial}
                                onChange={(newValue) => setDataInicial(newValue)} />
                        </div>

                        <div className="mt-4">
                            <DatePicker
                                disableFuture
                                label="Data Final"
                                value={dataFinal}
                                onChange={(newValue) => setDataFinal(newValue)} />
                        </div>

                        {dataErro ?
                            <div className="mt-3">
                                <Alert severity="error">
                                    {dataErroMensagem}
                                </Alert>
                            </div> : null
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDateDialogClose}>Cancelar</Button>
                        <Button onClick={handleAplicarFiltroData}>Aplicar Filtro</Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbar */}
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackBarClose}
                    TransitionComponent={Fade}>
                    <Alert onClose={handleSnackBarClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>

            </div>

            <Footer />

        </div >
    );
}

export default Home;