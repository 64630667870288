export const AEROPORTOS = [
    "Arraias (AAI)",
    "Aarhus (AAR)",
    "Abidjan (ABJ)",
    "Albuquerque (ABQ)",
    "Aberdeen (ABR)",
    "Abu Simbel (ABS)",
    "Albany (ABY)",
    "Aberdeen (ABZ)",
    "Acapulco (ACA)",
    "Accra (ACC)",
    "Lanzarote (ACE)",
    "Atlantic City (ACY)",
    "Adana (ADA)",
    "Izmir (ADB)",
    "Addis Ababa (ADD)",
    "Amman (ADJ)",
    "Adelaide (ADL)",
    "San Andres Island (ADZ)",
    "Buenos Aires (AEP)",
    "Akureyri (AEY)",
    "Alta Floresta (AFL)",
    "Agadir (AGA)",
    "Augsburg (AGB)",
    "Malaga (AGP)",
    "Agra (AGR)",
    "Alghero (AHO)",
    "Assis (AIF)",
    "Aracaju (AJU)",
    "Auckland (AKL)",
    "Almaty (ALA)",
    "Algiers (ALG)",
    "Alexandria (ALY)",
    "Amman (AMM)",
    "Amsterdam (AMS)",
    "Anchorage (ANC)",
    "Antofagasta (ANF)",
    "Saint Johns (ANU)",
    "Arapiraca (APQ)",
    "Apia (APW)",
    "Araraquara (AQA)",
    "Arequipa (AQP)",
    "Arica (ARI)",
    "Stockholm (ARN)",
    "Araçatuba (ARU)",
    "Aracati (ARX)",
    "Andros Town (ASD)",
    "Aspen (ASE)",
    "Asuncion (ASU)",
    "Athens (ATH)",
    "Atlanta (ATL)",
    "Altamira (ATM)",
    "Aruba (AUA)",
    "Abu Dhabi (AUH)",
    "Austin (AUS)",
    "Araguaína (AUX)",
    "Anguilla (AXA)",
    "Antalya (AYT)",
    "Kalamazoo (AZO)",
    "Bahrain (BAH)",
    "Baku (BAK)",
    "Barranquilla (BAQ)",
    "Barretos (BAT)",
    "Barcelos (BAZ)",
    "Bucharest (BBU)",
    "Barcelona (BCN)",
    "Boca do Acre (BCR)",
    "Hartford (BDL)",
    "Belgrade (BEG)",
    "Belém (BEL)",
    "Newcastle (BEO)",
    "Berlin (BER)",
    "Beirut (BEY)",
    "Belfast (BFS)",
    "Bangui (BGF)",
    "Bridgetown (BGI)",
    "Bergen (BGO)",
    "Bangor (BGR)",
    "Bagé (BGX)",
    "Milan (BGY)",
    "Belfast (BHD)",
    "Bahia Blanca (BHI)",
    "Birmingham (BHX)",
    "Bastia (BIA)",
    "Biak (BIK)",
    "Bimini (BIM)",
    "Bilbao (BIO)",
    "Banjul (BJL)",
    "Bragança Paulista (BJP)",
    "Leon/Guanajuato (BJX)",
    "Kota Kinabalu (BKI)",
    "Bangkok (BKK)",
    "Bamako (BKO)",
    "Billund (BLL)",
    "Bologna (BLQ)",
    "Bangalore (BLR)",
    "Blantyre (BLZ)",
    "Stockholm (BMA)",
    "Brumado (BMS)",
    "Nashville (BNA)",
    "Brisbane (BNE)",
    "Blumenau (BNU)",
    "Bogota (BOG)",
    "Bombay (BOM)",
    "Bonaire (BON)",
    "Boston (BOS)",
    "Barra dos Garças (BPG)",
    "Porto Seguro (BPS)",
    "Barra (BQQ)",
    "Barreiras (BRA)",
    "Barreirinhas (BRB)",
    "San Carlos De Bariloche (BRC)",
    "Bremen (BRE)",
    "Bari Palese (BRI)",
    "Bristol (BRS)",
    "Brussels (BRU)",
    "Brasília (BSB)",
    "Balsas (BSS)",
    "Budapest (BUD)",
    "Bulawayo (BUQ)",
    "Burbank (BUR)",
    "Boa Vista (BVA)",
    "Boa Vista (BVB)",
    "Vilhena (BVH)",
    "Belmonte (BVM)",
    "Baltimore (BWI)",
    "Bandar Seri Begawan (BWN)",
    "Bonito (BYO)",
    "Belize City (BZE)",
    "Cascavel (CAC)",
    "Carauari (CAF)",
    "Cagliari (CAG)",
    "Cairo (CAI)",
    "Guangzhou (CAN)",
    "Casablanca (CAS)",
    "Caruaru (CAU)",
    "Campos dos Goytacazes (CAW)",
    "Cayenne (CAY)",
    "Cochabamba (CBB)",
    "Canberra (CBR)",
    "Forquilhinha (CCM)",
    "Concepcion (CCP)",
    "Caracas (CCS)",
    "Calcutta (CCU)",
    "Cáceres (CCX)",
    "Paris (CDG)",
    "Cachoeiro de Itapemirim (CDI)",
    "Cebu (CEB)",
    "Cabo Frio (CFB)",
    "Cuiabá (CGB)",
    "Congonhas (CGH)",
    "Jakarta (CGK)",
    "Cologne/Bonn (CGN)",
    "Campo Grande (CGR)",
    "Christchurch (CHC)",
    "Chimbote (CHM)",
    "Charleston (CHS)",
    "Rome (CIA)",
    "Coari (CIZ)",
    "Cheju (CJU)",
    "Carajás (CKS)",
    "Conakry (CKY)",
    "Cleveland (CLE)",
    "Cali (CLO)",
    "Charlotte (CLT)",
    "Caldas Novas (CLV)",
    "Colombo (CMB)",
    "Camocim (CMC)",
    "Corumbá (CMG)",
    "Columbus (CMH)",
    "Casablanca (CMN)",
    "Belo Horizonte (CNF)",
    "Cairns (CNS)",
    "Canavieiras (CNV)",
    "Chiang Mai (CNX)",
    "Cordoba (COR)",
    "Colorado Springs (COS)",
    "San Martin De Los Andes (CPC)",
    "Copenhagen (CPH)",
    "Campo dos Amarais (CPQ)",
    "Cape Town (CPT)",
    "Campina Grande (CPV)",
    "Comodoro Rivadavia (CRD)",
    "Caravelas (CRQ)",
    "Carriacou Island (CRU)",
    "Cassilândia (CSS)",
    "Cartagena (CTG)",
    "Sapporo (CTS)",
    "Chengdu (CTU)",
    "Cucuta (CUC)",
    "Cuenca (CUE)",
    "Cancun (CUN)",
    "Willemstad (CUR)",
    "Chihuahua (CUU)",
    "Cuzco (CUZ)",
    "Cincinnati (CVG)",
    "Curitiba (CWB)",
    "Cardiff (CWL)",
    "Caxias do Sul (CXJ)",
    "Cayman Brac Island (CYB)",
    "Colonia (CYR)",
    "Cozumel (CZM)",
    "Cruzeiro do Sul (CZS)",
    "Daytona Beach (DAB)",
    "Dhaka (DAC)",
    "Da Nang (DAD)",
    "Dallas (DAL)",
    "Damascus (DAM)",
    "Dar Es Salaam (DAR)",
    "Dayton (DAY)",
    "Dubrovnik (DBV)",
    "Washington (DCA)",
    "Delhi (DEL)",
    "Denver (DEN)",
    "Dallas (DFW)",
    "Dhahran (DHA)",
    "Dijon (DIJ)",
    "Dili (DIL)",
    "Divinópolis (DIQ)",
    "Dakar (DKR)",
    "Douala (DLA)",
    "Dalian (DLC)",
    "Moscou (DME)",
    "Dianópolis (DNO)",
    "Doha (DOH)",
    "Dominica (DOM)",
    "Dourados (DOU)",
    "Denpasar Bali (DPS)",
    "Dresden (DRS)",
    "Des Moines (DSM)",
    "Dortmund (DTM)",
    "Detroit (DTW)",
    "Dublin (DUB)",
    "Durban (DUR)",
    "Dusseldorf (DUS)",
    "Dutch Harbor (DUT)",
    "Davao (DVO)",
    "Dubai (DXB)",
    "Entebbe (EBB)",
    "Edinburgh (EDI)",
    "Eindhoven (EIN)",
    "Tortola (EIS)",
    "North Eleuthera (ELH)",
    "El Paso (ELP)",
    "East Midlands (EMA)",
    "Medellin (EOH)",
    "Eirunepé (ERN)",
    "Windhoek (ERS)",
    "Ankara (ESB)",
    "Elat (ETH)",
    "Metz/Nancy (ETZ)",
    "Yerevan (EVN)",
    "Newark (EWR)",
    "Exeter (EXT)",
    "Key West (EYW)",
    "Buenos Aires (EZE)",
    "Faroe Islands (FAE)",
    "Fairbanks (FAI)",
    "Faro (FAO)",
    "Fargo (FAR)",
    "Fresno (FAT)",
    "Fonte Boa (FBA)",
    "Oslo (FBU)",
    "Rome (FCO)",
    "Fort De France (FDF)",
    "San Fernando (FDO)",
    "Feira de Santana (FEC)",
    "Fernando de Noronha (FEN)",
    "Fez (FEZ)",
    "Kinshasa (FIH)",
    "Floriano (FLB)",
    "Ft Lauderdale (FLL)",
    "Florianópolis (FLN)",
    "Florença (FLR)",
    "Münster (FMO)",
    "Funchal (FNC)",
    "Fortaleza (FOR)",
    "Freeport (FPO)",
    "Frankfurt (FRA)",
    "Franca (FRC)",
    "Fukuoka (FUK)",
    "Yamagata (GAJ)",
    "Gaborone (GBE)",
    "Guernsey (GCI)",
    "Grand Cayman (GCM)",
    "Grand Canyon (GCN)",
    "Guadalajara (GDL)",
    "Gdansk (GDN)",
    "Guadalupe (GDP)",
    "Grand Turk Is (GDT)",
    "Magadan (GDX)",
    "Oslo (GEN)",
    "Georgetown (GEO)",
    "George Town (GGT)",
    "Governors Harbour (GHB)",
    "Gibraltar (GIB)",
    "Galeão (GIG)",
    "Glasgow (GLA)",
    "Grenoble (GNB)",
    "St Georges (GND)",
    "Guanambi (GNM)",
    "Genoa (GOA)",
    "Gothenburg (GOT)",
    "Galapagos Islands (GPS)",
    "Gerona (GRO)",
    "Gurupi (GRP)",
    "Groningen (GRQ)",
    "Guarulhos (GRU)",
    "Granada (GRX)",
    "Graz (GRZ)",
    "Guatemala City (GUA)",
    "Guaratinguetá (GUJ)",
    "Guam (GUM)",
    "Guarapari (GUZ)",
    "Geneva (GVA)",
    "Governador Valadares (GVR)",
    "Coyhaique (GXQ)",
    "Guayaquil (GYE)",
    "Goiânia (GYN)",
    "The Hague (HAG)",
    "Moroni (HAH)",
    "Hanover (HAJ)",
    "Haikou (HAK)",
    "Hamburg (HAM)",
    "Hanoi (HAN)",
    "Havana (HAV)",
    "Hobart (HBA)",
    "Heidelberg (HDB)",
    "Helsinki (HEL)",
    "Heraklion (HER)",
    "Hiroshima (HIJ)",
    "Honiara (HIR)",
    "Hayman Island (HIS)",
    "Hakodate (HKD)",
    "Hong Kong (HKG)",
    "Phuket (HKT)",
    "Malmo (HMA)",
    "Honolulu (HNL)",
    "Houston (HOU)",
    "Kauai Island (HPV)",
    "Harbin (HRB)",
    "Harare (HRE)",
    "Hurghada (HRG)",
    "Kharkov (HRK)",
    "Hualien (HUN)",
    "Humaitá (HUW)",
    "Huatulco (HUX)",
    "Hyderabad (HYD)",
    "Washington (IAD)",
    "Houston (IAH)",
    "Ibiza (IBZ)",
    "Kiev (IEV)",
    "Kingman (IGM)",
    "Iguazu (IGR)",
    "Foz do Iguaçu (IGU)",
    "Irkutsk (IKT)",
    "Imperatriz (IMP)",
    "Innsbruck (INN)",
    "Nauru (INU)",
    "Inverness (INV)",
    "Isle Of Man (IOM)",
    "Ilhéus (IOS)",
    "Ilha da Páscoa (IPC)",
    "Santo Antônio do Içá (IPG)",
    "Ipoh (IPH)",
    "Ipiales (IPI)",
    "Ipiaú (IPU)",
    "Iquique (IQQ)",
    "Iquitos (IQT)",
    "Irecê (IRE)",
    "Santa Isabel do Rio Negro (IRZ)",
    "Islamabad (ISB)",
    "Isles Of Scilly (ISC)",
    "Istanbul (IST)",
    "Itacoatiara (ITA)",
    "Itaituba (ITB)",
    "Ituberá (ITE)",
    "Osaka (ITM)",
    "Itabuna (ITN)",
    "Hilo (ITO)",
    "Itaperuna (ITP)",
    "Zona da Mata (IZA)",
    "Izumo (IZO)",
    "Jaipur (JAI)",
    "Jacksonville (JAX)",
    "Joaçaba (JCB)",
    "Jacobina (JCM)",
    "Juiz de Fora (JDF)",
    "Jodhpur (JDH)",
    "Juazeiro do Norte (JDO)",
    "São João del-Rei (JDR)",
    "Jeddah (JED)",
    "Jequié (JEQ)",
    "Jersey (JER)",
    "New York (JFK)",
    "Djibouti (JIB)",
    "Jericoacoara (JJD)",
    "Jaguaruna (JJG)",
    "Jonkoping (JKG)",
    "Mikonos (JMK)",
    "Johannesburg (JNB)",
    "Naxos (JNX)",
    "Yogyakarta (JOG)",
    "Joinville (JOI)",
    "Johnston Island (JON)",
    "João Pessoa (JPA)",
    "Ji Paraná (JPR)",
    "Kilimanjaro (JRO)",
    "Bauru/Arealva (JTC)",
    "Santorini (JTR)",
    "Jujuy (JUJ)",
    "Juliaca (JUL)",
    "Kano (KAN)",
    "Kiev (KBP)",
    "Reykjavik (KEF)",
    "Kiel (KEL)",
    "Kaliningrad (KGD)",
    "Karachi (KHI)",
    "Kingston (KIN)",
    "Osaka (KIX)",
    "Krasnojarsk (KJA)",
    "Klagenfurt (KLU)",
    "Kunming (KMG)",
    "Miyazaki (KMI)",
    "Kumamoto (KMJ)",
    "Kona (KOA)",
    "Kagoshima (KOJ)",
    "Krakow (KRK)",
    "Kiruna (KRN)",
    "Karup (KRP)",
    "Khartoum (KRT)",
    "Kosrae (KSA)",
    "Kathmandu (KTM)",
    "Katowice (KTW)",
    "Kuala Lumpur (KUL)",
    "Kaunas (KUN)",
    "Kuopio (KUO)",
    "Kunsan (KUV)",
    "Kavala (KVA)",
    "Kwajalein (KWA)",
    "Kuwait (KWI)",
    "Guilin (KWL)",
    "Kazan (KZN)",
    "Luanda (LAD)",
    "Lages (LAJ)",
    "Las Vegas (LAS)",
    "Los Angeles (LAX)",
    "Bom Jesus da Lapa (LAZ)",
    "Leeds/Bradford (LBA)",
    "Lábrea (LBR)",
    "Libreville (LBV)",
    "Larnaca (LCA)",
    "La Ceiba (LCE)",
    "La Coruna (LCG)",
    "London (LCY)",
    "Londrina (LDB)",
    "Lourdes (LDE)",
    "Lençóis (LEC)",
    "Leipzig (LEJ)",
    "Leticia (LET)",
    "Lome (LFW)",
    "New York (LGA)",
    "Long Beach (LGB)",
    "Langkawi (LGK)",
    "London (LGW)",
    "Lahore (LHE)",
    "London (LHR)",
    "Lima (LIM)",
    "Milan (LIN)",
    "Lins (LIP)",
    "Lisboa (LIS)",
    "Ljubljana (LJU)",
    "Lilongwe (LLW)",
    "Linz (LNZ)",
    "Lagos (LOS)",
    "La Paz (LPB)",
    "Linkoping (LPI)",
    "Liverpool (LPL)",
    "Casa De Campo (LRM)",
    "Terre-De-Haut (LSS)",
    "London (LTN)",
    "Lugano (LUG)",
    "Lusaka (LUN)",
    "Luxembourg (LUX)",
    "Livingstone (LVI)",
    "Lvov (LWO)",
    "Lawas (LWY)",
    "Luxor (LXR)",
    "Lyon (LYS)",
    "Madras (MAA)",
    "Marabá (MAB)",
    "Madrid (MAD)",
    "Menorca (MAH)",
    "Majuro (MAJ)",
    "Manchester (MAN)",
    "Manaus (MAO)",
    "Maracaibo (MAR)",
    "Mombasa (MBA)",
    "Montego Bay (MBJ)",
    "Maués (MBZ)",
    "Kansas City (MCI)",
    "Monte Carlo (MCM)",
    "Orlando (MCO)",
    "Macapá (MCP)",
    "Muscat (MCT)",
    "Maceió (MCZ)",
    "Manado (MDC)",
    "Medellin (MDE)",
    "Mar Del Plata (MDQ)",
    "Chicago (MDW)",
    "Mendoza (MDZ)",
    "Macaé (MEA)",
    "Manta (MEC)",
    "Medinah (MED)",
    "Melbourne (MEL)",
    "Memphis (MEM)",
    "Medan (MES)",
    "Mexico City (MEX)",
    "Managua (MGA)",
    "Maringá (MGF)",
    "Mogadishu (MGQ)",
    "Miami (MIA)",
    "Marília (MII)",
    "Malta (MLA)",
    "Male (MLE)",
    "Mulhouse (MLH)",
    "Malmo (MMX)",
    "Montserrat (MNI)",
    "Manila (MNL)",
    "Manicoré (MNX)",
    "Montes Claros (MOC)",
    "Maputo (MPM)",
    "Marseille (MRS)",
    "Mauritius (MRU)",
    "Minneapolis (MSP)",
    "New Orleans (MSY)",
    "Monterrey (MTY)",
    "Maun (MUB)",
    "Munich (MUC)",
    "Maturin (MUN)",
    "Montevideo (MVD)",
    "Mossoró (MVF)",
    "Mucuri (MVS)",
    "Milan (MXP)",
    "Nadi (NAN)",
    "Naples (NAP)",
    "Nassau (NAS)",
    "Natal (NAT)",
    "Nairobi (NBO)",
    "North Caicos (NCA)",
    "Nice (NCE)",
    "Newcastle (NCL)",
    "Annecy (NCY)",
    "Ndjamena (NDJ)",
    "Nevis (NEV)",
    "Nagoya (NGO)",
    "Nagasaki (NGS)",
    "Nouakchott (NKC)",
    "Nanjing (NKG)",
    "Ndola (NLA)",
    "Nuevo Laredo (NLD)",
    "Nelspruit (NLP)",
    "Noumea (NOU)",
    "Tokyo (NRT)",
    "Yaounde (NSI)",
    "Nelson (NSN)",
    "Nantes (NTE)",
    "Newcastle (NTL)",
    "Miracema do Tocantins (NTM)",
    "Nuremberg (NUE)",
    "Novo Aripuanã (NVP)",
    "Navegantes (NVT)",
    "Oakland (OAK)",
    "Cacoal (OAL)",
    "Oaxaca (OAX)",
    "Odense (ODE)",
    "Okinawa (OKA)",
    "Oklahoma City (OKC)",
    "Okayama (OKJ)",
    "Olbia-Costa Smeralda (OLB)",
    "São Paulo de Olivença (OLC)",
    "Ontario (ONT)",
    "Miami (OPF)",
    "Porto (OPO)",
    "Sinop (OPS)",
    "Orebro (ORB)",
    "Chicago (ORD)",
    "Norfolk (ORF)",
    "Cork (ORK)",
    "Orlando (ORL)",
    "Oran (ORN)",
    "Paris (ORY)",
    "Oshkosh (OSH)",
    "Bucharest (OTP)",
    "Ouagadougou (OUA)",
    "Oulu (OUL)",
    "Ourinhos (OUS)",
    "Novosibirsk (OVB)",
    "Asturias (OVD)",
    "Bissau (OXB)",
    "Ouarzazate (OZZ)",
    "Paderborn (PAD)",
    "Port Au Prince (PAP)",
    "Patna (PAT)",
    "Paulo Afonso (PAV)",
    "Poza Rica (PAZ)",
    "Paranaíba (PBB)",
    "Puebla (PBC)",
    "West Palm Beach (PBI)",
    "Paramaribo (PBM)",
    "Picos (PCS)",
    "Prado (PDF)",
    "Padang (PDG)",
    "Ponta Delgada (PDL)",
    "Portland (PDX)",
    "Beijing (PEK)",
    "Penang (PEN)",
    "Perth (PER)",
    "Peshawar (PEW)",
    "Passo Fundo (PFB)",
    "Paphos (PFO)",
    "Ponta Grossa (PGZ)",
    "Parnaíba (PHB)",
    "Pinheiro (PHI)",
    "Philadelphia (PHL)",
    "Phoenix (PHX)",
    "Nassau (PID)",
    "Glasgow (PIK)",
    "Parintins (PIN)",
    "Pittsburgh (PIT)",
    "Providenciales (PLS)",
    "Pampulha (PLU)",
    "Puerto Montt (PMC)",
    "Ponta Porã (PMG)",
    "Palma Mallorca (PMI)",
    "Palermo (PMO)",
    "Palmas (PMW)",
    "Porto Nacional (PNB)",
    "Phnom Penh (PNH)",
    "Pohnpei (PNI)",
    "Petrolina (PNZ)",
    "Porto Alegre (POA)",
    "Port Gentil (POG)",
    "Patos de Minas (POJ)",
    "Port Moresby (POM)",
    "Poços de Caldas (POO)",
    "Puerto Plata (POP)",
    "Port Of Spain (POS)",
    "Presidente Prudente (PPB)",
    "Pago Pago (PPG)",
    "Papeete (PPT)",
    "Prague (PRG)",
    "Praslin Island (PRI)",
    "Pisa (PSA)",
    "Puerto Suarez (PSZ)",
    "Malololailai (PTF)",
    "Pato Branco (PTO)",
    "Pointe A Pitre (PTP)",
    "Panama City (PTY)",
    "Punta Cana (PUJ)",
    "Punta Arenas (PUQ)",
    "Pusan (PUS)",
    "Shangai (PVG)",
    "Porto Velho (PVH)",
    "Puerto Vallarta (PVR)",
    "Puerto Escondido (PXM)",
    "Puerto Ordaz (PZO)",
    "Barbacena (QAK)",
    "Vail/Eagle (QBF)",
    "Botucatu (QCP)",
    "Dracena (QDC)",
    "Duesseldorf (QDU)",
    "Jundiaí (QDV)",
    "Lençóis Paulista (QGC)",
    "Piracicaba (QHB)",
    "Taguatinga (QHN)",
    "Breckenridge (QKB)",
    "Cologne (QKL)",
    "Queretaro (QRO)",
    "Resende (QRZ)",
    "São Carlos (QSC)",
    "Sydney (QSY)",
    "Avaré/Arandu (QVP)",
    "Rabaul (RAB)",
    "Praia (RAI)",
    "Marrakech (RAK)",
    "Ribeirão Preto (RAO)",
    "Rarotonga (RAR)",
    "Rabat (RBA)",
    "Borba (RBB)",
    "Rio Branco (RBR)",
    "Raleigh/Durham (RDU)",
    "Recife (REC)",
    "Reggio Calabria (REG)",
    "Trelew (REL)",
    "Resistencia (RES)",
    "Rio Grande (RGA)",
    "Rio Gallegos (RGL)",
    "Yangon (RGN)",
    "Rhodes (RHO)",
    "Santa Maria (RIA)",
    "Richmond (RIC)",
    "Riga (RIX)",
    "Reykjavik (RKV)",
    "Rome (ROM)",
    "Rondonópolis (ROO)",
    "Rota (ROP)",
    "Koror (ROR)",
    "Rosario (ROS)",
    "Rostov (ROV)",
    "Roatan (RTB)",
    "Rotterdam (RTM)",
    "Riyadh (RUH)",
    "Rio Verde (RVD)",
    "Saba Island (SAB)",
    "Santa Fe (SAF)",
    "Sanaa (SAH)",
    "San Salvador (SAL)",
    "San Diego (SAN)",
    "San Pedro Sula (SAP)",
    "San Antonio (SAT)",
    "Savannah (SAV)",
    "Santa Barbara (SBA)",
    "St Barthelemy (SBH)",
    "São Mateus (SBJ)",
    "Santiago (SCL)",
    "Saarbruecken (SCN)",
    "Santiago De Compostela (SCQ)",
    "Santiago (SCU)",
    "Sendai (SDJ)",
    "Sto. Domingo (SDQ)",
    "Santander (SDR)",
    "Santos Dumont (SDU)",
    "Seattle (SEA)",
    "Seoul (SEL)",
    "Serra Talhada (SET)",
    "Mahe Island (SEZ)",
    "Sfax (SFA)",
    "St Martin (SFG)",
    "Santa Fe (SFN)",
    "San Francisco (SFO)",
    "Ho Chi Minh City (SGN)",
    "Shanghai (SHA)",
    "Sharjah (SHJ)",
    "Sal (SID)",
    "Singapore (SIN)",
    "San Jose (SJC)",
    "Sarajevo (SJJ)",
    "São José dos Campos (SJK)",
    "São Gabriel da Cachoeira (SJL)",
    "San Jose (SJO)",
    "São José do Rio Preto (SJP)",
    "San Juan (SJU)",
    "Shijiazhuang (SJW)",
    "St Kitts (SKB)",
    "Thessaloniki (SKG)",
    "Skopje (SKP)",
    "Salta (SLA)",
    "Salt Lake City (SLC)",
    "St Lucia (SLU)",
    "São Luís (SLZ)",
    "Sacramento (SMF)",
    "Sorriso (SMT)",
    "Santa Ana (SNA)",
    "Shannon (SNN)",
    "Bartolomeu de Gusmão (SNZ)",
    "Sorocaba (SOD)",
    "Sofia (SOF)",
    "St Thomas Island (SPB)",
    "Saipan (SPN)",
    "San Pedro (SPR)",
    "Sucre (SRE)",
    "Sarasota (SRQ)",
    "Salvador (SSA)",
    "Sharm El Sheikh (SSH)",
    "St Louis (STL)",
    "Santarém (STM)",
    "London (STN)",
    "Stuttgart (STR)",
    "St Thomas Island (STT)",
    "St Croix Island (STX)",
    "Surabaya (SUB)",
    "Suva (SUV)",
    "St Vincent (SVD)",
    "Stavanger (SVG)",
    "Moscou (SVO)",
    "Sevilla (SVQ)",
    "Savusavu (SVU)",
    "Swakopmund (SWP)",
    "Strasbourg (SXB)",
    "Berlin (SXF)",
    "St Maarten (SXM)",
    "Sydney (SYD)",
    "Salzburg (SZG)",
    "Shenzhen (SZX)",
    "Tobago (TAB)",
    "Taegu (TAE)",
    "Tampico (TAM)",
    "Tashkent (TAS)",
    "Tbilisi (TBS)",
    "Tabatinga (TBT)",
    "Nuku Alofa (TBU)",
    "Tefé (TFF)",
    "Tenerife (TFN)",
    "Tenerife (TFS)",
    "Tangará da Serra (TGQ)",
    "Tegucigalpa (TGU)",
    "Teresina (THE)",
    "Berlin (THF)",
    "Tehran (THR)",
    "Tirana (TIA)",
    "Tijuana (TIJ)",
    "Tinian (TIQ)",
    "Três Lagoas (TJL)",
    "Truk (TKK)",
    "Tokushima (TKS)",
    "Turku (TKU)",
    "Tallahassee (TLH)",
    "Tallinn (TLL)",
    "Toulouse (TLS)",
    "Tel Aviv Yafo (TLV)",
    "Catalão (TLZ)",
    "Tangier (TNG)",
    "Antananarivo (TNR)",
    "Toledo (TOL)",
    "Tromso (TOS)",
    "Toyama (TOY)",
    "Tampa (TPA)",
    "Taipei (TPE)",
    "Torreon (TRC)",
    "Trondheim (TRD)",
    "Turin (TRN)",
    "Trieste (TRS)",
    "Trujillo (TRU)",
    "Trivandrum (TRV)",
    "Tiruchirapally (TRZ)",
    "Taipei (TSA)",
    "Timisoara (TSR)",
    "New York (TSS)",
    "Tottori (TTJ)",
    "Tucuman (TUC)",
    "Tours (TUF)",
    "Tulsa (TUL)",
    "Tunis (TUN)",
    "Taupo (TUO)",
    "Tucson (TUS)",
    "Teixeira de Freitas (TXF)",
    "Berlin (TXL)",
    "Belize City (TZA)",
    "South Andros (TZN)",
    "Trabzon (TZX)",
    "Narsarsuaq (UAK)",
    "San Juan (UAQ)",
    "Uberaba (UBA)",
    "Ube (UBJ)",
    "Ubatuba (UBT)",
    "Uberlândia (UDI)",
    "Udaipur (UDR)",
    "Quito (UIO)",
    "Ulan Bator (ULN)",
    "Ulyanovsk (ULY)",
    "Umea (UME)",
    "Umuarama (UMU)",
    "Una (UNA)",
    "Urumqi (URC)",
    "Uruguaiana (URG)",
    "Surat Thani (URT)",
    "Ushuaia (USH)",
    "Koh Samui (USM)",
    "Ulsan (USN)",
    "Udon Thani (UTH)",
    "St Lucia (UVF)",
    "Vaasa (VAA)",
    "Valença (VAL)",
    "Varna (VAR)",
    "Sivas (VAS)",
    "Visby (VBY)",
    "Venice (VCE)",
    "Viracopos (VCP)",
    "Vitória da Conquista (VDC)",
    "Viedma Gobernador Castello (VDM)",
    "Valdez (VDZ)",
    "Victoria Falls (VFA)",
    "Vigo (VGO)",
    "Vienna (VIE)",
    "Virgin Gorda (VIJ)",
    "Vitoria (VIT)",
    "Vitória (VIX)",
    "Moscou (VKO)",
    "Valencia (VLC)",
    "Villa Gesell (VLG)",
    "Port Vila (VLI)",
    "Valladolid (VLL)",
    "Valencia (VLN)",
    "Vilnius (VNO)",
    "Varanasi (VNS)",
    "Volgograd (VOG)",
    "Votuporanga (VOT)",
    "Varadero (VRA)",
    "Vero Beach (VRB)",
    "Verona (VRN)",
    "Villahermosa (VSA)",
    "Vientiane (VTE)",
    "Santa Cruz (VVI)",
    "Vladivostok (VVO)",
    "São Vicente (VXE)",
    "Vaxjo (VXO)",
    "Waterford (WAT)",
    "Warsaw (WAW)",
    "Windhoek (WDH)",
    "Nairobi (WIL)",
    "Wellington (WLG)",
    "Wroclaw (WRO)",
    "Chapecó (XAP)",
    "Yap (YAP)",
    "Banff (YBA)",
    "Deer Lake (YDF)",
    "Yechon (YEC)",
    "Edmonton (YEG)",
    "Dryden (YHD)",
    "Hamilton (YHM)",
    "Halifax (YHZ)",
    "Yakutsk (YKS)",
    "Kelowna (YLW)",
    "Montreal (YMX)",
    "Ottawa (YOW)",
    "Quebec (YQB)",
    "Windsor (YQG)",
    "Regina (YQR)",
    "Gander (YQX)",
    "Toronto (YTZ)",
    "Montreal (YUL)",
    "Yuma (YUM)",
    "Vancouver (YVR)",
    "Deer Lake (YVZ)",
    "Winnipeg (YWG)",
    "Victoria (YWH)",
    "Edmonton (YXD)",
    "Calgary (YYC)",
    "Victoria (YYJ)",
    "Goose Bay (YYR)",
    "St Johns (YYT)",
    "Kapuskasing (YYU)",
    "Mont Joli (YYY)",
    "Toronto (YYZ)",
    "Yellowknife (YZF)",
    "Zagreb (ZAG)",
    "Zahedan (ZAH)",
    "Valdivia (ZAL)",
    "Zamboanga (ZAM)",
    "Zaragoza (ZAZ)",
    "Vail/Eagle (ZBV)",
    "Zacatecas (ZCL)",
    "Temuco (ZCO)",
    "Zhanjiang (ZHA)",
    "Ixtapa (ZIH)",
    "Manzanillo (ZLO)",
    "Zanzibar (ZNZ)",
    "Zurich (ZRH)",
    "San Salvador (ZSA)",
    "Zakinthos (ZTH)",    
];