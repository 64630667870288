import { Button, Typography } from "@mui/material";
import MenuAppBar from "./MenuAppBar";
import { useNavigate, useParams } from "react-router-dom";

function Error() {

    const navigate = useNavigate();
    let { errorCode } = useParams();

    return (
        <div>
            <MenuAppBar />
            <div className="container">
                <div className="row mt-4">
                    <Typography variant="h4">
                        {"Ops :("}
                    </Typography>
                </div>
                <div className="row mt-3">
                    <Typography variant="body1">
                        Se você está nesta página, alguma coisa não aconteceu como deveria. Sentimos muito e vamos trabalhar para melhorar.<br />
                        Enquanto isso, você pode tentar novamente ou falar com o suporte:
                    </Typography>
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <Button
                            variant="text"
                            onClick={() => navigate("/login")}>
                            Tentar novamente
                        </Button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <Button
                            variant="text"
                            onClick={() => window.open(
                                "https://wa.me/5537999666240?text=Ol%C3%A1,%20preciso%20de%20suporte%20para%20o%20aplicativo%20Travels%20(erro%20" + errorCode + ").",
                                "_blank")
                            }>
                            Falar com suporte
                        </Button>
                    </div>
                </div>
                {errorCode ?
                    <div>
                        <div className="row mt-3">
                            <Typography
                                variant="body2"
                                style={{ fontStyle: 'italic', color: "gray" }}>
                                Código do erro: {errorCode}
                            </Typography>
                        </div>
                    </div>
                    : null}
            </div>
        </div >
    );
}

export default Error;