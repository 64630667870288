import { Alert, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fade, Link, Snackbar, TextField, Typography } from "@mui/material";
import MenuAppBar from "./MenuAppBar";
import { NavigateNext } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import {
    getAuth,
    updateProfile,
    updateEmail,
    updatePassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    deleteUser
} from "firebase/auth";
import { ERRO_DELETAR_CONTA, ERRO_OBTER_DADOS_USUARIO, ERRO_REAUTENTICAR, ERRO_SALVAR_PERFIL, getFirebaseError, sendError } from "../Errors";
import { useNavigate } from 'react-router-dom';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Footer from "./Footer";

function Profile(props) {

    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;

    const db = getFirestore(getApp());
    const userDataRef = doc(db, "users", user.uid);

    const storage = getStorage();

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState("success");
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [salvarDisabled, setSalvarDisabled] = useState(false);

    const [email, setEmail] = useState("");
    const [nome, setNome] = useState("");
    const [novaSenha, setNovaSenha] = useState("");
    const [senhaAtual, setSenhaAtual] = useState("");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [nomeAgencia, setNomeAgencia] = useState("");
    const [telefoneContato, setTelefoneContato] = useState("");
    const [enderecoCompleto, setEnderecoCompleto] = useState("");
    const [mensagemRodape, setMensagemRodape] = useState("");
    const [logo, setLogo] = useState(null);

    const [reauthDialogOpen, setReauthDialogOpen] = useState(false);
    const [reauthCallback, setReauthCallback] = useState("");

    useEffect(() => {

        // Get profile info
        setEmail(user.email);
        setNome(user.displayName);

        // Get user info
        getDoc(userDataRef)
            .then((docSnap) => {
                let data = docSnap.data();
                setNomeAgencia(data.nomeAgencia);
                setTelefoneContato(data.telefoneContato);
                setEnderecoCompleto(data.enderecoCompleto);
                setMensagemRodape(data.mensagemRodape);
                setLogo(data.logo ?? "");
                console.log("data", data);
            }).catch((error) => {
                sendError(ERRO_OBTER_DADOS_USUARIO, error);
                navigate("/error/" + ERRO_OBTER_DADOS_USUARIO.code);
            });

    }, []);

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    };

    const onEmailChange = (email) => {
        setEmail(email);
    };

    const onNomeChange = (nome) => {
        setNome(nome);
    };

    const onNovaSenhaChange = (novaSenha) => {
        setNovaSenha(novaSenha);
    };

    const onSenhaAtualChange = (senhaAtual) => {
        setSenhaAtual(senhaAtual);
    };

    const handleReauthenticateDialogClose = () => {
        setReauthDialogOpen(false);
    };

    const onNomeAgenciaChange = (nomeAgencia) => {
        setNomeAgencia(nomeAgencia);
    };

    const onTelefoneContatoChange = (telefoneContato) => {
        setTelefoneContato(telefoneContato);
    };

    const onEnderecoCompletoChange = (enderecoCompleto) => {
        setEnderecoCompleto(enderecoCompleto);
    };

    const onMensagemRodapeChange = (mensagemRodape) => {
        setMensagemRodape(mensagemRodape);
    };

    const onLogoChange = (arquivoLogo) => {
        setLogo(arquivoLogo);
    };

    const handleReauthenticateDialogConfirm = (callback) => {

        const credential = EmailAuthProvider.credential(
            user.email,
            senhaAtual
        );
        reauthenticateWithCredential(
            user,
            credential
        ).then((response) => {

            setReauthDialogOpen(false);

            switch (reauthCallback) {
                case "onSave":
                    onSave();
                    break;
                case "handleDeleteAccount":
                    handleDeleteAccount();
                    break;
                default:
            }

        }).catch((error) => {

            setReauthDialogOpen(false);

            let errorMessage = getFirebaseError(error);
            if (errorMessage) {
                setSnackBarMessage(errorMessage);
                setSnackBarSeverity("error");
                setSnackBarOpen(true);
            } else {
                sendError(ERRO_REAUTENTICAR, error);
                navigate("/error/" + ERRO_REAUTENTICAR.code);
            }

        });

    };

    const onSave = async () => {

        try {

            if (telefoneContato) {
                let numeros = telefoneContato.replace(/\D/g, "");
                if (![10, 11].includes(numeros.length)) {
                    setSnackBarMessage("Informe um telefone com 10 ou 11 dígitos.");
                    setSnackBarSeverity("error");
                    setSnackBarOpen(true);
                    return;
                }
            }

            // Update name
            await updateProfile(user, {
                displayName: nome,
                //photoURL: "https://example.com/jane-q-user/profile.jpg"
            });

            // Update e-mail
            await updateEmail(user, email);

            // Update password if entered
            if (novaSenha) {
                await updatePassword(user, novaSenha);
            }

            // Update logo
            let logoNova = null;
            if (logo instanceof File) {
                let extension = logo.name.split('.').pop();
                logoNova = "logos/" + user.uid + "." + extension;
                let logoRef = ref(storage, logoNova);
                await uploadBytes(logoRef, logo);
            }

            // Update user info
            await setDoc(userDataRef, {
                "email": user.email,
                "nomeAgencia": nomeAgencia,
                "telefoneContato": telefoneContato,
                "enderecoCompleto": enderecoCompleto,
                "mensagemRodape": mensagemRodape,
                "logo": logoNova ?? logo
            }, {
                merge: true
            });

            setSnackBarMessage("Informações atualizadas com sucesso.");
            setSnackBarSeverity("success");
            setSnackBarOpen(true);

        } catch (error) {

            // Verifica se precisa reautenticar
            if (error.code === "auth/requires-recent-login") {
                setSenhaAtual("");
                setReauthDialogOpen(true);
                setReauthCallback("onSave");
                return;
            }

            let errorMessage = getFirebaseError(error);
            if (errorMessage) {
                setSnackBarMessage(errorMessage);
                setSnackBarSeverity("error");
                setSnackBarOpen(true);
            } else {
                sendError(ERRO_SALVAR_PERFIL, error);
                navigate("/error/" + ERRO_SALVAR_PERFIL.code);
            }

        } finally {
            setSalvarDisabled(false);
        }

    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteAccount = () => {
        deleteUser(user).then(() => {

            // TODO: delete user data, quotes and logo
            navigate("/login");

        }).catch((error) => {
            if (error.code === "auth/requires-recent-login") {
                setReauthDialogOpen(true);
                setReauthCallback("handleDeleteAccount");
            } else {
                sendError(ERRO_DELETAR_CONTA, error);
                navigate("/error/" + ERRO_DELETAR_CONTA.code);
            }
        });
    };

    const viewImage = async (url) => {
        const imageRef = ref(storage, url);
        const downloadURL = await getDownloadURL(imageRef);
        window.open(downloadURL, '_blank');
    };

    const getLogoElement = () => {

        if (!logo) {
            return null;
        }

        if (logo instanceof File) {
            return logo.name;
        }

        return <>
            <span style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => viewImage(logo)}>
                Ver Logo
            </span>
        </>

    };

    return (
        <div>
            <MenuAppBar />

            <div className="container">

                <Breadcrumbs
                    className="mt-3"
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/home">
                        Home
                    </Link>
                    <Typography color="text.primary">Minha Conta</Typography>
                </Breadcrumbs>

                <Divider className="mt-3" sx={{ borderBottomWidth: 3 }} />

                <Typography className="mt-3" variant="h5">
                    Informações pessoais
                </Typography>

                {/* Snackbar */}
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackBarClose}
                    TransitionComponent={Fade}>
                    <Alert onClose={handleSnackBarClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>

                {/* Nome */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            fullWidth
                            id="nome"
                            label="Nome"
                            variant="outlined"
                            value={nome || ""}
                            onChange={(event) => { onNomeChange(event.target.value) }} />
                    </div>
                </div>

                {/* E-mail */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            fullWidth
                            required
                            id="email"
                            label="E-mail"
                            variant="outlined"
                            value={email}
                            onChange={(event) => { onEmailChange(event.target.value) }} />
                    </div>
                </div>

                {/* Nova Senha */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            autoComplete="new-password"
                            fullWidth
                            type="password"
                            id="nova-senha"
                            label="Nova Senha"
                            variant="outlined"
                            value={novaSenha}
                            onChange={(event) => { onNovaSenhaChange(event.target.value) }} />
                    </div>
                </div>

                {/* Botão excluir conta */}
                <div className="row">
                    <div className="col-md-3 mt-3">
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => setDeleteDialogOpen(true)}>
                            Excluir Conta
                        </Button>
                    </div>
                </div>

                <Typography className="mt-3" variant="h5">
                    Informações do orçamento
                </Typography>

                {/* Nome da agência */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            fullWidth
                            id="nome-agencia"
                            label="Nome da agência"
                            variant="outlined"
                            value={nomeAgencia || ""}
                            onChange={(event) => { onNomeAgenciaChange(event.target.value) }} />
                    </div>
                </div>

                {/* Telefone de contato */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            fullWidth
                            id="telefone-contato"
                            label="Telefone de contato (WhatsApp)"
                            variant="outlined"
                            placeholder="(99) 99999-9999"
                            value={telefoneContato || ""}
                            onChange={(event) => { onTelefoneContatoChange(event.target.value) }} />
                    </div>
                </div>

                {/* Endereço completo */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            id="endereco-completo"
                            label="Endereço completo"
                            variant="outlined"
                            value={enderecoCompleto || ""}
                            onChange={(event) => { onEnderecoCompletoChange(event.target.value) }} />
                    </div>
                </div>

                {/* Mensagem Rodapé */}
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            id="mensagem-rodape"
                            label="Mensagem do rodapé"
                            variant="outlined"
                            value={mensagemRodape || ""}
                            onChange={(event) => { onMensagemRodapeChange(event.target.value) }} />
                    </div>
                </div>

                {/* Escolher logo */}
                <div className="row">
                    <div className="col-md-6 col-lg-3 mt-3">
                        <Button
                            variant="text"
                            component="label">
                            Escolher Logo
                            <input hidden
                                accept="image/jpeg, image/png"
                                type="file"
                                onChange={(event) => onLogoChange(event.target.files[0])} />
                        </Button>
                    </div>
                    <div className="col-md-6 col-lg-3 mt-3 d-flex align-items-center">
                        <div style={{ minWidth: "0px" }}> {/* https://css-tricks.com/flexbox-truncated-text/ */}
                            <Typography variant="body2" className="text-truncate">
                                {
                                    getLogoElement()
                                }
                            </Typography>
                        </div>
                    </div>
                </div>

                <Divider className="mt-3" sx={{ borderBottomWidth: 3 }} />

                {/* Botão salvar */}
                <div className="row">
                    <div className="col-md-6 col-lg-3"></div>
                    <div className="col-md-6 col-lg-3 mt-3 mb-3">
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={salvarDisabled}
                            onClick={() => {
                                setSalvarDisabled(true);
                                onSave();
                            }}>
                            Salvar
                        </Button>
                    </div>
                </div>

                { /* Dialog para informar a senha */}
                <Dialog
                    open={reauthDialogOpen}
                    onClose={handleReauthenticateDialogClose}>
                    <DialogTitle>Informe sua senha</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Informe a sua senha atual para prosseguir com esta operação.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            fullWidth
                            margin="dense"
                            autoComplete="new-password"
                            type="password"
                            id="password"
                            label="Senha"
                            variant="standard"
                            value={senhaAtual}
                            onChange={(event) => { onSenhaAtualChange(event.target.value) }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReauthenticateDialogClose}>Cancelar</Button>
                        <Button onClick={handleReauthenticateDialogConfirm}>Confirmar</Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog excluir conta */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description">
                    <DialogTitle id="delete-dialog-title">
                        Excluir Conta
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            Tem certeza que deseja excluir sua conta?
                            <br />
                            Esta ação não poderá ser revertida.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="error" onClick={handleDeleteAccount}>Excluir</Button>
                        <Button onClick={handleDeleteDialogClose}>Cancelar</Button>
                    </DialogActions>
                </Dialog>

            </div>

            <Footer />

        </div >
    );
}

export default Profile;